import { IconType, IconVariant } from '@isp/icon';
import { Markets } from '@Constants/market';
import { FinalVehicleType } from '@Constants/product';

export enum AssignedDivisionKeys {
	BUS = 'retailers:assigned-division-bus',
	CAR = 'retailers:assigned-division-car',
	FUSO = 'retailers:assigned-division-fuso',
	SMART = 'retailers:assigned-division-smart',
	TRUCK = 'retailers:assigned-division-truck',
	UNIMOG = 'retailers:assigned-division-unimog',
	VAN = 'retailers:assigned-division-van',
}

export enum DealerStatuses {
	APPROVED = 'APPROVED',
	PENDING = 'PENDING',
	NOT_ANSWERED = 'NOT_ANSWERED',
}

export enum WebPartsStatuses {
	APPROVED_AND_PREFERRED_RETAILER_EXISTS = 'APPROVED_AND_PREFERRED_RETAILER_EXISTS',
	APPROVED_AND_NO_PREFERRED_RETAILER = 'APPROVED_AND_NO_PREFERRED_RETAILER',
	RETAILER_APPROVAL_PENDING = 'RETAILER_APPROVAL_PENDING',
	NOT_ACCEPTED = 'NOT_ACCEPTED',
}

export const RESTRICTED_MARKET = Markets.HONG_KONG;
export const RESTRICTED_DIVISION = FinalVehicleType.VAN;
export const MAX_LENGTH_TOOLBAR_STRING = 35;
export const MIN_NUMBER_OF_RETAILER_SHOWN_DROPDOWN = 2;
export const PART_PRO_PLUS_DEALER_LOGO_SIZE = { width: 92, height: 39 };
export const PART_PRO_PLUS_DEALER_LOGO_SIZE_SMALL = { width: 70, height: 30 };

export const DEALER_STATUS_MAPPING = {
	[DealerStatuses.PENDING]: {
		iconType: IconType.CLOCK,
		iconVariant: IconVariant.BLUE,
		description: 'retailers:request-pending',
		showHideButton: false,
	},
	[DealerStatuses.NOT_ANSWERED]: {
		iconType: IconType.ERROR2,
		iconVariant: IconVariant.BLOODRED,
		description: 'retailers:request-not-answered',
		showHideButton: true,
	},
};

export enum AddNewPartnerStates {
	SELECT = 'SELECT',
	LOADING = 'LOADING',
	SUCCESSFUL = 'SUCCESSFUL',
	ERROR = 'ERROR',
}

export enum AddRetailerErrorCodes {
	MISSING_EMAIL_FOR_CUSTOMER_COMPANY = 'MISSING_EMAIL_FOR_CUSTOMER_COMPANY',
	NOT_REGISTERED_DEALER = 'NOT_REGISTERED_DEALER',
}

export enum RetailerLegalTextType {
	TERMS_AND_CONDITIONS = 'generalTermsOfConditions',
	DECLARATION_OF_USE = 'salesRestriction',
	DATA_PROTECTION = 'dataProtectionDeclaration',
	OEM_WARRANTY_TEXT = 'oemWarrantyText',
}

export enum RetailerContactLocations {
	REMAN_REQUEST_MODAL = 'REMAN_REQUEST_MODAL',
	SUPPORT_MODAL = 'SUPPORT_MODAL',
}

export const SLICE_HOURS_INDEX = 5;

export enum RetailerOpeningHoursBrands {
	MB,
	smart,
}

export enum RetailerOpeningHoursServices {
	PARTS,
	SERVICE,
	SERVICE_24H,
}

export enum RetailerOpeningHoursProductGroups {
	PASSENGER_CAR,
	VAN,
}

export enum RetailerOpeningHoursDays {
	MONDAY,
	TUESDAY,
	WEDNESDAY,
	THURSDAY,
	FRIDAY,
	SATURDAY,
	SUNDAY,
}

export const OpeningHourTranslation = {
	[RetailerOpeningHoursDays.MONDAY]: 'common:monday-abbreviation',
	[RetailerOpeningHoursDays.TUESDAY]: 'common:tuesday-abbreviation',
	[RetailerOpeningHoursDays.WEDNESDAY]: 'common:wednesday-abbreviation',
	[RetailerOpeningHoursDays.THURSDAY]: 'common:thursday-abbreviation',
	[RetailerOpeningHoursDays.FRIDAY]: 'common:friday-abbreviation',
	[RetailerOpeningHoursDays.SATURDAY]: 'common:saturday-abbreviation',
	[RetailerOpeningHoursDays.SUNDAY]: 'common:sunday-abbreviation',
};
