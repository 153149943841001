import { Component } from 'react';
import { Provider } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import Router from 'next/router';
import queryString from 'query-string';
import { initializeStore } from '@Redux';
import { getAllMarkets, getMarketConfigurations, getApplicationSettings } from '@Configurations/index';
import { getUserInfo } from '@User-operations';
import routes, { getRoutesAs } from '@Routes';
import { initialUserState } from '@Reducers/user/reducers';
import { initialConfigurationState } from '@Reducers/configuration/reducers';
import { initialSectionsState } from '@Reducers/sections/reducers';
import { FooterSectionType, HeaderSectionType } from '@Reducers/sections/models';
import { getFooterContent, getHeaderContent } from '@Helpers/content';
import { initialOrganizationState } from '@Reducers/organization/reducers';
import { HTTPResponseCodes, RedirectionKeys } from '@Constants/common';
import { EssentialCookies, FunctionalCookies } from '@Constants/cookies';
import {
	LANG_EXCEPTIONS_FOR_CURRENCY_AT_START,
	MARKETS_NOT_USING_DECIMALS,
	MARKETS_USING_COMMA_SEPERATOR,
	MARKETS_USING_CURRENCY_AT_START,
} from '@Constants/shop';
import { MarketLangMap, Markets } from '@Constants/market';
import { UserRoles } from '@Constants/user';

const isServer = typeof window === 'undefined';
const __NEXT_REDUX_STORE__ = '__NEXT_REDUX_STORE__';

export function getOrCreateStore(initialState) {
	// Always make a new store if server, otherwise state is shared between requests
	if (isServer) {
		return initializeStore(initialState);
	}

	// Create store if unavailable on the client and set it on the window object
	if (!window[__NEXT_REDUX_STORE__]) {
		window[__NEXT_REDUX_STORE__] = initializeStore(initialState);
	}
	return window[__NEXT_REDUX_STORE__];
}

function redirectToMarket(appContext, marketToRedirect, initialQueryString) {
	if (appContext.ctx.res) {
		appContext.ctx.res.writeHead(HTTPResponseCodes.FOUND, {
			Location: `/${marketToRedirect}${initialQueryString}`,
		});
		return appContext.ctx.res.end();
	}
	return Router.push(routes.HOME, getRoutesAs(`${routes.HOME}${initialQueryString}`, { market: marketToRedirect }));
}

const App = App =>
	class AppWithRedux extends Component<{}, {}> {
		static async getInitialProps(appContext) {
			const appContextInstance = appContext;
			const { req } = appContext.ctx;
			let configuration = initialConfigurationState;
			let sections = initialSectionsState;
			let appLanguage = null;
			let user: any = {};
			let applicationSettings: any = {};
			let featureConfiguration: any = {};
			let footerContent: FooterSectionType = {
				footerMenuExternalUrls: [],
				newsletter: null,
				footerGroups: [],
			};
			let headerContent: HeaderSectionType = {
				menuItems: [],
			};
			let cookie;
			const { router } = appContext;

			if (router && router.query && router.query.market && router.query.market !== router.query.market.toLowerCase()) {
				const { res } = appContext.ctx;
				if (res) {
					const { market } = router.query;
					res.writeHead(HTTPResponseCodes.PERMANENTLY_MOVED, {
						Location: getRoutesAs(router.pathname, { market: market.toLowerCase() }),
					});
					return res.end();
				}
			}

			if (router.route === '/health' || router.route === '/_error' || router.route.startsWith('/global')) {
				let appProps = {};
				if (typeof App.getInitialProps === 'function') {
					appProps = await App.getInitialProps(appContextInstance);
				}
				return { ...appProps };
			}

			let reduxStore = getOrCreateStore({});

			const configurationState = reduxStore.getState().configuration;
			if (!(configurationState || {}).market) {
				const markets = ((await getAllMarkets()) || []).map(m => m.toUpperCase());
				const market = (
					appContext.router.query.market ||
					appContext.router.asPath?.split('/')?.[1] ||
					''
				).toUpperCase();

				if (!markets.includes(market.toUpperCase()) || appContext.router.pathname === '/404') {
					let defaultMarket = markets[0];

					if (markets.includes(market.toUpperCase())) {
						defaultMarket = market;
					}

					const initialQueryString = req._parsedUrl.search === null ? '' : req._parsedUrl.search;

					redirectToMarket(
						appContext,
						Object.keys(RedirectionKeys).includes(market) ? RedirectionKeys[market] : defaultMarket.toLowerCase(),
						initialQueryString
					);
					return {
						initialReduxState: reduxStore.getState(),
					};
				}

				// API JWT Check
				try {
					cookie = req.cookies[EssentialCookies.API_JWT] || null;
					if (cookie) {
						const userInfo = await getUserInfo(cookie);
						user = { ...initialUserState, ...userInfo };
					} else {
						throw new Error('No API JWT');
					}
				} catch (err) {
					user = initialUserState;
					appContext.ctx.res.cookie(EssentialCookies.API_JWT, '');
					appContext.ctx.res.cookie(EssentialCookies.ASP_NET_CORE_COOKIES, '');
					appContext.ctx.res.cookie(EssentialCookies.ASP_NET_CORE_SESSION, '');
				}

				// ASP User Check
				const isASPUser = (user?.role || []).includes(UserRoles.ASP);
				user = { ...user, isASPUser };

				const isASPPath = router.pathname.split('/')?.[2] === 'asp';

				if (isASPUser && !isASPPath) {
					appContext.ctx.res.writeHead(HTTPResponseCodes.FOUND, {
						Location: `${getRoutesAs(routes.ASP_HOME, { ...router.query })}`,
					});
					return appContext.ctx.res.end();
				}
				if (!isASPUser && isASPPath) {
					appContext.ctx.res.writeHead(HTTPResponseCodes.FOUND, {
						Location: getRoutesAs(routes.HOME, { ...router.query }),
					});
					return appContext.ctx.res.end();
				}

				// Fetch Market Configurations
				const [confRes, appSettingRes] = await Promise.all([getMarketConfigurations(market), getApplicationSettings()]);
				configuration = confRes;

				// Disable standardisedNavigation for ASP user
				if (isASPUser) {
					configuration.featureConfiguration.standardisedNavigation = false;
				}

				({ featureConfiguration } = confRes || {});
				applicationSettings = appSettingRes;
			} else {
				configuration = configurationState;
			}

			// Market & Language Settings
			const defaultLanguage = get(configuration, 'languageConfiguration.defaultLanguage');
			const market = (appContext.router.query.market || '').toUpperCase();

			if (req) {
				const availableLanguages = get(configuration, 'languageConfiguration.availableLanguages');
				const preferredLang = req.cookies[`${FunctionalCookies.ISP_USER_PREFERRED_LANGUAGE}_${market.toLowerCase()}`];

				if (user.isUserLoggedIn && preferredLang && availableLanguages && availableLanguages.includes(preferredLang)) {
					req.i18n.changeLanguage(preferredLang);
					appLanguage = preferredLang;
				} else {
					req.i18n.changeLanguage(defaultLanguage);
				}
			}

			// Parse querystring
			const qS = req?._parsedUrl?.search || null;
			user.loginError = null;

			if (qS !== null) {
				const parsedQueryString = queryString.parse(qS);
				if (parsedQueryString.loginError && parsedQueryString.loginError !== '') {
					user.loginError = parsedQueryString.loginError;
				}
			}

			const langAvailable = appLanguage || defaultLanguage;

			// Price Configuration
			const isDecimalSeparationComma = MARKETS_USING_COMMA_SEPERATOR.includes(market.toLowerCase() as Markets);
			const isCurrencyAtStart =
				MARKETS_USING_CURRENCY_AT_START.includes(market.toLowerCase() as Markets) &&
				!LANG_EXCEPTIONS_FOR_CURRENCY_AT_START.includes(`${market.toUpperCase()}_${langAvailable}` as MarketLangMap);
			const isDecimalUsed = !MARKETS_NOT_USING_DECIMALS.includes(market.toLowerCase() as Markets);

			// Header & Footer Content Fetch
			const sectionState = reduxStore.getState().sections;
			sections = sectionState;
			if (isEmpty(sectionState.headerContent.menuItems)) {
				[footerContent, headerContent] = await Promise.all([
					getFooterContent(market, langAvailable),
					getHeaderContent(market, langAvailable),
				]);
				sections = {
					...sections,
					footerContent,
					headerContent,
					sidebar: [],
				};
			}

			// Add configurations into redux
			reduxStore = getOrCreateStore({
				configuration: {
					...configuration,
					applicationSettings,
					priceConfiguration: {
						isCurrencyAtStart,
						isDecimalSeparationComma,
						isDecimalUsed,
					},
					featureConfiguration,
					language: appLanguage || defaultLanguage,
				},
				user,
				organization: initialOrganizationState,
				sections,
			});

			// Provide the store to getInitialProps of pages
			appContextInstance.ctx.reduxStore = reduxStore;

			let appProps = {};
			if (typeof App.getInitialProps === 'function') {
				appProps = await App.getInitialProps(appContextInstance);
			}

			return {
				...appProps,
				initialReduxState: reduxStore.getState(),
			};
		}

		constructor(props) {
			super(props);
			this.reduxStore = getOrCreateStore(props.initialReduxState);
		}

		reduxStore: any;

		render() {
			return (
				<Provider store={this.reduxStore}>
					<App {...this.props} reduxStore={this.reduxStore} />
				</Provider>
			);
		}
	};

export default App;
