import Router from 'next/router';
import { ROUTE } from '@Constants/home';

const routes = {
	HOME: '/[market]',
	DASHBOARD: '/[market]/dashboard',
	ORDERS: '/[market]/orders',
	LICENSES: '/[market]/licenses',
	RETAILERS: '/[market]/retailers',
	YOUR_ACCOUNT: '/[market]/user',
	COMPANY: '/[market]/company',
	NEWS: '/[market]/news',
	NEWS_DETAIL: '/[market]/news/[id]',
	CHEMICAL_PRODUCTS_AND_CAR_CARE: '/[market]/products/chemical-products-and-car-care',
	ORIGINAL_ENGINE_OIL: '/[market]/products/original-engine-oil',
	TIRES: '/[market]/products/mercedes-benz-tires',
	TRADE_CLUB: '/[market]/products/trade-club',
	STAR_PARTS: '/[market]/products/star-parts',
	PARTS: '/[market]/products/genuine-parts', // alias for the new genuine parts page for navigation
	GENUINE_PARTS: '/[market]/products/genuine-parts',
	GENUINE_PARTS_CATEGORIES: '/[market]/products/genuine-parts/[vehicle]/[categories]',
	GENUINE_PARTS_PRODUCTS: '/[market]/products/genuine-parts/[vehicle]/[categories]/[products]',
	GENUINE_ADVANTAGES: '/[market]/products/genuine-parts/your-benefits',
	GENUINE_CUSTOM_PAGE: '/[market]/products/genuine-parts/[customPage]',
	REMAN_PARTS: '/[market]/products/remanufactured-parts',
	REMAN_PARTS_VEHICLE: '/[market]/products/remanufactured-parts/[vehicle]',
	REMAN_PARTS_CATEGORIES: '/[market]/products/remanufactured-parts/[vehicle]/[categories]',
	REMAN_PARTS_PRODUCTS: '/[market]/products/remanufactured-parts/[vehicle]/[categories]/[products]',
	REMAN_MANUFACTURING_PROCESS: '/[market]/products/remanufactured-parts/our-remanufacturing-process',
	REMAN_PARTS_ENGINE_SUGGESTIONS: '/[market]/products/remanufactured-parts/[vehicle]/[categories]/engine-suggestions',
	REMAN_ADVANTAGES: '/[market]/products/remanufactured-parts/your-benefits',
	XENTRY_DIAGNOSIS_SYSTEM: '/[market]/workshop-solutions/diagnosis/xentry-diagnosis-system',
	WIS: '/[market]/shop/workshop-solutions/xentry-wis',
	ASRA: '/[market]/shop/workshop-solutions/xentry-operation-time',
	PARTS_INFORMATION: '/[market]/shop/workshop-solutions/parts-information',
	XENTRY_TIPS: '/[market]/shop/workshop-solutions/xentry-tips',
	RMS: '/[market]/shop/workshop-solutions/remote-maintenance-support',
	DIGITAL_SERVICE_BOOKLET: '/[market]/workshop-solutions/rmi/digital-service-booklet',
	PTI: '/[market]/workshop-solutions/rmi/pti',
	XENTRY_PASS_THRU_EU: '/[market]/shop/workshop-solutions/xentry-pass-thru-eu',
	RDS: '/[market]/shop/workshop-solutions/remote-diagnostic-support',
	XD_LITE: '/[market]/workshop-solutions/diagnosis/xentry-diagnosis-lite',
	XRD: '/[market]/shop/workshop-solutions/xentry-remote-diagnosis-app',
	B2B_CONNECT_APP: '/[market]/shop/workshop-solutions/b2b-connect-app',
	HELP: '/[market]/help',
	COOKIE_SETTINGS: '/[market]/help/cookies',
	PLATFORM_SEARCH: '/[market]/help/search/[keyword]',
	UNDER_CONSTRUCTION: '/[market]/under-construction',
	FAQ: '/[market]/help/faq',
	FAQ_CATEGORY: '/[market]/help/faq/[...categories]',
	XENTRY_FLASH: '/[market]/workshop-solutions/diagnosis/xentry-flash',
	XENTRY_SCOPE: '/[market]/shop/workshop-solutions/xentry-scope',
	RETAIL_DATA_STORAGE: '/[market]/workshop-solutions/diagnosis/retail-data-storage',
	XENTRY_ACCESSORIES: '/[market]/workshop-solutions/diagnosis/xentry-accessories',
	FREE_CONTENT: '/[market]/workshop-solutions/rmi/free-content',
	PRODUCTS: '/[market]/products',
	WORKSHOP_SOLUTIONS: '/[market]/workshop-solutions',
	TECHNICAL_DATA: '/[market]/technical-data',
	NAVIGATION: '/[market]/help/navigation',
	NAVIGATION_CATEGORIES: '/[market]/help/navigation/[...categories]',
	NOT_FOUND: '[market]/not-found',
	PARTS_APP: '/[market]/workshop-solutions/mobile-apps/parts-app',
	SEARCH_EQUIPMENT: '/[market]/shop/workshop-equipment/search-equipment',
	BASKET: '/[market]/basket',
	FAILED: '/[market]/payment/failed',
	SUCCESSFUL: '/[market]/payment/successful',
	LEGAL: '/[market]/legal',
	LEGAL_TAB: '/[market]/legal/[tab]',
	MARKETING_CAMPAIGN: '/[market]/campaign/[keyword]',
	MARKETING_CAMPAIGN_LEGAL_TAB: '/[market]/campaign/[keyword]/legal/[tab]',
	COMMUNICATION_EVENT: '/[market]/tool-tips',
	CATALOGUE: '/[market]/catalog',
	CATALOGUE_PARTS: '/[market]/catalog/parts',
	CATALOGUE_PACKAGES: '/[market]/catalog/packages',
	CATALOGUE_MATERIAL_PARTS: '/[market]/catalog/material-parts',
	CATALOGUE_OVERVIEW: '/[market]/catalog/overview',
	CATALOGUE_REPAIR: '/[market]/catalog/repair',
	CATALOGUE_CAMPAIGNS: '/[market]/catalog/campaigns',
	CATALOGUE_HELP: '/[market]/catalog/help',
	PAY_PER_VIN: '/[market]/shop/workshop-solutions/pay-per-vin',
	PERSONAL_SETTINGS: '/[market]/personal-settings',
	// ASP Routes
	ASP_HOME: '/[market]/asp',
	ASP_CATALOGUE: '/[market]/asp/catalog',
	ASP_CATALOGUE_PARTS: '/[market]/asp/catalog/parts',
	ASP_CATALOGUE_MATERIAL_PARTS: '/[market]/asp/catalog/material-parts',
	ASP_CATALOGUE_PACKAGES: '/[market]/asp/catalog/packages',
	ASP_CATALOGUE_HELP: '/[market]/asp/catalog/help',
	ASP_LEGAL: '/[market]/asp/legal',
	ASP_LEGAL_TAB: '/[market]/asp/legal/[tab]',
	ASP_HELP: '/[market]/asp/help',
	ASP_FAQ: '/[market]/asp/help/faq',
	ASP_FAQ_CATEGORIES: '/[market]/asp/faq/[...categories]',
};

const replacer = (route, data) => {
	let match;
	while ((match = ROUTE.exec(route))) {
		route = route.replace(match[0], data[match[1]]);
		ROUTE.lastIndex = 0;
	}
	return route;
};

export function getRoutesAs(route, options) {
	return replacer(route, options);
}

export const validateCurrentRoute = route => {
	const { market } = Router.query;
	const currentRoute = getRoutesAs(route, { market });

	return window.location.pathname === currentRoute;
};

export const isRouteCatalog = () => {
	const { market } = Router.query;
	const currentRoute = window.location.pathname;
	return (
		currentRoute.includes(getRoutesAs(routes.CATALOGUE, { market })) ||
		currentRoute.includes(getRoutesAs(routes.ASP_CATALOGUE, { market }))
	);
};

export const isRouteMaterialParts = () => {
	const { market } = Router.query;
	const currentRoute = window.location.pathname;
	return (
		currentRoute.includes(getRoutesAs(routes.CATALOGUE_MATERIAL_PARTS, { market })) ||
		currentRoute.includes(getRoutesAs(routes.ASP_CATALOGUE_MATERIAL_PARTS, { market }))
	);
};

export const isRouteCatalogCampaigns = () => {
	const { market } = Router.query;
	const currentRoute = window.location.pathname;
	return currentRoute.includes(getRoutesAs(routes.CATALOGUE_CAMPAIGNS, { market }));
};

export default routes;
