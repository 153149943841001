import { IconType } from '@isp/icon';
import routes from '@Routes';

export const SIDEBAR_ANIMATION_DELAY = 300;
export const SIDEBAR_EXPAND_BUTTON_HEIGHT = 60;

export enum SidebarGroups {
	HOME = 'HOME',
	ACCOUNT = 'ACCOUNT',
	CATALOGUE = 'CATALOGUE',
}

export enum SidebarTabs {
	VEHICLE_LIST = 'VEHICLE_LIST',
	ACCOUNT = 'ACCOUNT',
	OVERVIEW = 'OVERVIEW',
	VEHICLE_PARTS = 'VEHICLE_PARTS',
	ORDERS = 'ORDERS',
	LICENSES = 'LICENSES',
	RETAILER = 'RETAILER',
	USER = 'USER',
	COMPANY = 'COMPANY',
	REPAIR = 'REPAIR',
	NEWS = 'NEWS',
	HELP = 'HELP',
	PARTS_CATALOGUE = 'PARTS_CATALOGUE',
	CATALOGUE_OVERVIEW = 'CATALOGUE_OVERVIEW',
	PARTS = 'PARTS',
	MATERIAL_PARTS = 'MATERIAL_PARTS',
	PACKAGES = 'PACKAGES',
	WHEELS = 'WHEELS',
	CATALOGUE_REPAIR = 'CATALOGUE_REPAIR',
	CATALOGUE_CAMPAIGNS = 'CATALOGUE_CAMPAIGNS',
	CATALOGUE_HELP = 'CATALOGUE_HELP',
	SETTINGS = 'SETTINGS',
}

export enum ASPSidebarTabs {
	HOME = 1,
	PARTS_CATALOGUE,
	HELP,
	PARTS,
	PACKAGES,
	MATERIAL_PARTS,
	CATALOGUE_HELP,
}

export const SidebarTabOptions = {
	[SidebarTabs.VEHICLE_LIST]: {
		route: routes.HOME,
		defaultIcon: IconType.VEHICLE_LIST,
	},
	[SidebarTabs.ACCOUNT]: {
		route: '',
		defaultIcon: IconType.ACCOUNT,
	},
	[SidebarTabs.OVERVIEW]: {
		route: routes.CATALOGUE_OVERVIEW,
		defaultIcon: IconType.PKW_OUTLINE,
	},
	[SidebarTabs.VEHICLE_PARTS]: {
		route: routes.CATALOGUE_PARTS,
		defaultIcon: IconType.VEHICLE_PARTS,
	},
	[SidebarTabs.REPAIR]: {
		route: routes.CATALOGUE_REPAIR,
		defaultIcon: IconType.MECHANIC,
	},
	[SidebarTabs.ORDERS]: {
		route: routes.ORDERS,
		defaultIcon: IconType.ORDERS,
	},
	[SidebarTabs.LICENSES]: {
		route: routes.LICENSES,
		defaultIcon: IconType.LICENSES,
	},
	[SidebarTabs.RETAILER]: {
		route: routes.RETAILERS,
		defaultIcon: IconType.MERCEDES_BENZ,
	},
	[SidebarTabs.USER]: {
		route: routes.YOUR_ACCOUNT,
		defaultIcon: IconType.USER_PLAIN,
	},
	[SidebarTabs.COMPANY]: {
		route: routes.COMPANY,
		defaultIcon: IconType.COMPANY,
	},
	[SidebarTabs.NEWS]: {
		route: routes.NEWS,
		defaultIcon: IconType.NEWS,
	},
	[SidebarTabs.HELP]: {
		route: routes.HELP,
		defaultIcon: IconType.QUESTION_MARK_CIRCLE,
	},
	[SidebarTabs.PARTS_CATALOGUE]: {
		route: routes.CATALOGUE,
		defaultIcon: IconType.VEHICLE_PARTS,
	},
	[SidebarTabs.CATALOGUE_OVERVIEW]: {
		route: routes.CATALOGUE_OVERVIEW,
		defaultIcon: IconType.PKW_OUTLINE,
	},
	[SidebarTabs.PARTS]: {
		route: routes.CATALOGUE_PARTS,
		defaultIcon: IconType.VEHICLE_PARTS,
	},
	[SidebarTabs.MATERIAL_PARTS]: {
		route: routes.CATALOGUE_MATERIAL_PARTS,
		defaultIcon: IconType.CHEMICAL_PRODUCTS2,
	},
	[SidebarTabs.PACKAGES]: {
		route: routes.CATALOGUE_PACKAGES,
		defaultIcon: IconType.DELIVERY,
	},
	[SidebarTabs.WHEELS]: {
		route: '',
		defaultIcon: IconType.WHEEL_EXTERNAL,
	},
	[SidebarTabs.CATALOGUE_REPAIR]: {
		route: routes.CATALOGUE_REPAIR,
		defaultIcon: IconType.MECHANIC,
	},
	[SidebarTabs.CATALOGUE_CAMPAIGNS]: {
		route: routes.CATALOGUE_CAMPAIGNS,
		defaultIcon: IconType.DISCOUNT_OUTLINE,
	},
	[SidebarTabs.CATALOGUE_HELP]: {
		route: routes.CATALOGUE_HELP,
		defaultIcon: IconType.QUESTION_MARK_CIRCLE,
	},
	[SidebarTabs.SETTINGS]: {
		route: routes.PERSONAL_SETTINGS,
		defaultIcon: IconType.SETTINGS,
	},
};

export const SIDEBAR_FALLBACK = [
	{
		identifier: SidebarTabs.VEHICLE_LIST,
		group: SidebarGroups.HOME,
		text: 'Vehicles',
		icon: IconType.VEHICLE_LIST,
		route: routes.HOME,
	},
	{
		identifier: SidebarTabs.PARTS_CATALOGUE,
		group: SidebarGroups.HOME,
		text: 'Parts',
		icon: IconType.VEHICLE_PARTS,
		route: routes.CATALOGUE,
	},
	{
		identifier: SidebarTabs.WHEELS,
		group: SidebarGroups.HOME,
		text: 'Wheels & Trims Tool',
		icon: IconType.WHEEL_EXTERNAL,
		route: routes.CATALOGUE_CAMPAIGNS,
	},
	{
		identifier: SidebarTabs.ACCOUNT,
		group: SidebarGroups.HOME,
		text: 'Account',
		icon: IconType.ACCOUNT,
		route: routes.ORDERS,
	},
	{
		identifier: SidebarTabs.ORDERS,
		group: SidebarGroups.ACCOUNT,
		text: 'Orders',
		icon: IconType.ORDERS,
		route: routes.ORDERS,
	},
	{
		identifier: SidebarTabs.LICENSES,
		group: SidebarGroups.ACCOUNT,
		text: 'Licenses',
		icon: IconType.LICENSES,
		route: routes.LICENSES,
	},
	{
		identifier: SidebarTabs.RETAILER,
		group: SidebarGroups.ACCOUNT,
		text: 'Retailer',
		icon: IconType.MERCEDES_BENZ,
		route: routes.RETAILERS,
	},
	{
		identifier: SidebarTabs.USER,
		group: SidebarGroups.ACCOUNT,
		text: 'User',
		icon: IconType.USER_PLAIN,
		route: routes.YOUR_ACCOUNT,
	},
	{
		identifier: SidebarTabs.COMPANY,
		group: SidebarGroups.ACCOUNT,
		text: 'Company',
		icon: IconType.COMPANY,
		route: routes.COMPANY,
	},
	{
		identifier: SidebarTabs.CATALOGUE_OVERVIEW,
		group: SidebarGroups.CATALOGUE,
		text: 'Details',
		icon: IconType.PKW_OUTLINE,
		route: routes.CATALOGUE_OVERVIEW,
	},
	{
		identifier: SidebarTabs.PARTS,
		group: SidebarGroups.CATALOGUE,
		text: 'Parts',
		icon: IconType.VEHICLE_PARTS,
		route: routes.CATALOGUE_PARTS,
	},
	{
		identifier: SidebarTabs.MATERIAL_PARTS,
		group: SidebarGroups.CATALOGUE,
		text: 'Paints & Operating Fluids',
		icon: IconType.CHEMICAL_PRODUCTS,
		route: routes.CATALOGUE_MATERIAL_PARTS,
	},
	{
		identifier: SidebarTabs.CATALOGUE_REPAIR,
		group: SidebarGroups.CATALOGUE,
		text: 'Working on the vehicle',
		icon: IconType.MECHANIC,
		route: routes.CATALOGUE_REPAIR,
	},
	{
		identifier: SidebarTabs.CATALOGUE_CAMPAIGNS,
		group: SidebarGroups.CATALOGUE,
		text: 'Limited offer',
		icon: IconType.DISCOUNT_OUTLINE,
		route: routes.CATALOGUE_CAMPAIGNS,
	},
	{
		identifier: SidebarTabs.SETTINGS,
		group: SidebarGroups.ACCOUNT,
		text: 'Settings',
		icon: IconType.SETTINGS,
		route: routes.PERSONAL_SETTINGS,
	},
];
