import { ChapterStatus } from '@isp/chapter-button';
import { actionTypes } from '@Reducers/vehicle/actionTypes';
import {
	AggregateTypes,
	CHAPTER_TYPES,
	DEFAULT_VEHICLE_LIST_PAGE_INDEX,
	DEFAULT_VEHICLE_LIST_PAGE_SIZE,
	FinBasedPartSearchResponseType,
	INITIAL_VEHICLE_DETAILS_DATA,
	SortingButtonGroupType,
	VehicleSection,
} from '@Constants/vehicle';
import { ResponseCode } from '@Constants/common';

export const initialVehicleState = {
	vehicles: {
		totalCount: 0,
		list: [],
	},
	vehicleListPageIndex: DEFAULT_VEHICLE_LIST_PAGE_INDEX,
	lastLoadedVehicleIndex: DEFAULT_VEHICLE_LIST_PAGE_SIZE,
	selectedVehicle: null,
	generalSearchKeyword: null,
	partLoading: false,
	shouldScrollToKDM: false,
	shouldScrollToVehicleOrders: false,
	partSearchResponseCode: ResponseCode.NOT_FOUND,
	partSearchResponseType: FinBasedPartSearchResponseType.NONE,
	vehicleDetails: INITIAL_VEHICLE_DETAILS_DATA,
	vehicleDetailsLoading: true,
	vehicleDetailsError: false,
	part: null,
	vehiclePart: null,
	exportPDFToggled: false,
	serviceDocumentationForm: {
		serviceType: false,
		serviceDate: 0,
		mileage: '',
		remainingTime: '',
		remainingDistance: '',
		nextServiceAt: '',
		nextServiceOn: '',
		mileageUntilNextService: '',
	},
	serviceDocumentationFormErrors: {
		remainingDistanceErrors: false,
		remainingTimeErrors: false,
		nextServiceAtErrors: false,
		nextServiceOnErrors: false,
		mileageUntilNextServiceErrors: false,
	},
	highlightedVehicleVIN: null,
	validationTrigger: 0,
	allFilteredWorksSelected: false,
	additionalWorkSelected: false,
	showDsbSavedNotification: false,
	savedServiceIndex: 0,
	chapterStatuses: {
		[CHAPTER_TYPES.WORKSHOP_INFORMATION]: ChapterStatus.DEFAULT,
		[CHAPTER_TYPES.SERVICE_INFORMATION]: ChapterStatus.DEFAULT,
		[CHAPTER_TYPES.VEHICLE_INFORMATION]: ChapterStatus.DEFAULT,
	},
	exportPDFError: false,
	exportKDMPDFError: false,
	isDistanceUnitMileage: false,
	pageSection: VehicleSection.OVERVIEW,
	dsbData: null,
	dsbLoading: false,
	dsbError: false,
	serviceHistory: undefined,
	serviceHistoryDetail: null,
	serviceDetailLoading: false,
	serviceDetailError: false,
	serviceDocumentation: null,
	serviceDocumentationLoading: null,
	serviceDocumentationError: null,
	serviceDocumentationCancelToken: null,
	showAllServiceRecords: false,
	fieldMeasureData: null,
	isResultPart: false,
	isKDMLoading: false,
	isDWDAvailableForVehicle: null,
	explosionDrawingHighlightedParts: [],
	vehicleListActiveSortingButton: SortingButtonGroupType.LAST_OPENED,
	recentOrderFilterAvailable: false,
	vehiclesLoading: true,
	vehiclesError: false,
	updatedVehicleNote: '',
	searchResponseCode: null,
	toBeDeletedVehicleCount: 0,
	deletedVehicleVin: '',
	recentlyOpenedVehicles: [],
	recentlyOpenedVehiclesError: false,
	fillingsDependencyFailed: false,
	fillingsData: [],
	catalogs: [],
	oldVehicleRemark: false,
	aggregateSelection: [],
	selectedMainGroupOptions: null,
	selectedCatalog: null,
	allSupplementaryParts: null,
	isVehicleCardOpened: false,
	vehicleDataCardAvailable: false,
	vehicleNoteWithVin: { vin: undefined, vehicleNote: undefined },
	isResultValidVin: false,
};

export const vehicleReducer = (state = initialVehicleState, action) => {
	switch (action.type) {
		case actionTypes.GET_KDM_INFO:
			return {
				...state,
				fieldMeasureData: action.fieldMeasureData,
				isKDMLoading: action.isKDMLoading,
			};
		case actionTypes.CLEAR_VEHICLE_DETAILS:
		case actionTypes.GET_NON_SAVED_VEHICLE_DETAILS:
		case actionTypes.GET_VEHICLE_DETAILS:
			return {
				...state,
				vehicleDetails: action.vehicleDetails,
				vehicleDetailsLoading: action.vehicleDetailsLoading,
				vehicleDetailsError: action.vehicleDetailsError,
			};
		case actionTypes.RESET_VEHICLE_DETAILS:
			return {
				...state,
				vehicleDetails: action.vehicleDetails,
			};
		case actionTypes.GET_FILTERED_VEHICLE_LIST:
			return {
				...state,
				vehicles: action.vehicles,
				vehiclesLoading: action.vehiclesLoading,
				vehiclesError: action.vehiclesError,
			};
		case actionTypes.SET_VEHICLE_LIST:
		case actionTypes.SET_FILTERED_VEHICLE_LIST:
			return {
				...state,
				vehicles: action.vehicles,
			};
		case actionTypes.GET_USER_VEHICLES_OR_PART:
			return {
				...state,
				vehicles: action.vehicles,
				part: action.part,
				searchResponseCode: action.searchResponseCode,
				vehiclesLoading: action.vehiclesLoading,
				partLoading: action.partLoading,
				isResultPart: action.isResultPart,
				isResultValidVin: action.isResultValidVin,
			};
		case actionTypes.SET_SEARCHED_VEHICLES:
			return {
				...state,
				vehicles: action.vehicles,
				vehiclesLoading: action.vehiclesLoading,
				searchResponseCode: action.searchResponseCode,
				isResultPart: action.isResultPart,
			};
		case actionTypes.SET_SEARCHED_PART:
			return {
				...state,
				part: action.part,
				partLoading: action.partLoading,
				searchResponseCode: action.searchResponseCode,
				vehiclesLoading: action.vehiclesLoading,
				isResultPart: action.isResultPart,
			};
		case actionTypes.GET_VEHICLE_PARTS_BY_VIN_NUMBER:
		case actionTypes.GET_SEARCH_PARTS_BY_PART_NUMBER:
			return {
				...state,
				partSearchResponseCode: action.partSearchResponseCode,
				partSearchResponseType: action.partSearchResponseType,
				vehiclePart: action.vehiclePart,
				partLoading: action.partLoading,
			};

		case actionTypes.GET_VEHICLE_PARTS_BY_VIN_AND_PART_NUMBER:
			return {
				...state,
				partSearchResponseCode: action.partSearchResponseCode,
				partSearchResponseType: action.partSearchResponseType,
				vehiclePart: action.vehiclePart,
				partLoading: action.partLoading,
			};

		case actionTypes.GET_VEHICLE_INFO:
			return {
				...state,
				vehicleInfo: action.vehicleInfo,
				vehicleInfoLoading: action.vehicleInfoLoading,
			};
		case actionTypes.GET_VEHICLE_VIN_VALIDATION:
			return {
				...state,
				vehicleVinValidation: action.vehicleVinValidation,
				vehicleVinValidationLoading: action.vehicleVinValidationLoading,
			};
		case actionTypes.SAVE_VEHICLE:
			return {
				...state,
				vehicleSaveLoading: action.vehicleSaveLoading,
			};
		case actionTypes.DELETE_VEHICLE:
			return {
				...state,
				vehicleDeleteLoading: action.vehicleDeleteLoading,
			};

		case actionTypes.SET_DSB_SERVICES:
			return {
				...state,
				dsbData: action.dsbData,
			};
		case actionTypes.UPDATE_SERVICE_DOCUMENTATION_FORM:
			return {
				...state,
				serviceDocumentationForm: action.serviceDocumentationForm,
			};
		case actionTypes.UPDATE_SERVICE_DOCUMENTATION_FORM_ERRORS:
			return {
				...state,
				serviceDocumentationFormErrors: action.serviceDocumentationFormErrors,
			};
		case actionTypes.UPDATE_DSB_EXPORT_ERROR:
			return {
				...state,
				exportPDFError: action.exportPDFError,
			};
		case actionTypes.UPDATE_KDM_EXPORT_ERROR:
			return {
				...state,
				exportKDMPDFError: action.exportKDMPDFError,
			};
		case actionTypes.SET_SELECTED_VEHICLE:
			return {
				...state,
				selectedVehicle: action.selectedVehicle,
			};
		case actionTypes.SET_HIGHLIGHTED_VEHICLE_VIN:
			return {
				...state,
				highlightedVehicleVIN: action.highlightedVehicleVIN,
			};
		case actionTypes.UPDATE_CHAPTER_NAV_BUTTON_STATUS:
			return {
				...state,
				chapterStatuses: action.chapterStatuses,
			};
		case actionTypes.TRIGGER_VALIDATION:
		case actionTypes.RESET_VALIDATION_COUNTER:
			return {
				...state,
				validationTrigger: action.validationTrigger,
			};
		case actionTypes.UPDATE_ADDITIONAL_CHECKBOXES_STATUS:
			return {
				...state,
				additionalWorkSelected: action.additionalWorkSelected,
			};
		case actionTypes.UPDATE_FILTERED_CHECKBOXES_STATUS:
			return {
				...state,
				allFilteredWorksSelected: action.allFilteredWorksSelected,
			};
		case actionTypes.TOGGLE_DSB_SAVED_NOTIFICATION:
			return {
				...state,
				showDsbSavedNotification: action.showDsbSavedNotification,
				savedServiceIndex: action.savedServiceIndex,
			};
		case actionTypes.RESET_DOCUMENTATION_FORM:
			return {
				...state,
				serviceDocumentationFormErrors: action.serviceDocumentationFormErrors,
				serviceDocumentationForm: action.serviceDocumentationForm,
			};
		case actionTypes.MAP_VEHICLE_DSB_EDIT_DATA:
			return {
				...state,
				serviceDocumentationForm: action.serviceDocumentationForm,
			};

		case actionTypes.UPDATE_DSB_DATA:
			return {
				...state,
				dsbData: action.dsbData,
			};
		case actionTypes.UPDATE_GENERAL_SEARCH_KEYWORD:
			return {
				...state,
				generalSearchKeyword: action.generalSearchKeyword,
			};
		case actionTypes.SET_VEHICLE_LIST_LAST_LOADED_INDEX:
			return {
				...state,
				lastLoadedVehicleIndex: action.lastLoadedVehicleIndex,
			};
		case actionTypes.SET_VEHICLE_LIST_PAGE_INDEX:
			return {
				...state,
				vehicleListPageIndex: action.vehicleListPageIndex,
			};
		case actionTypes.TOGGLE_EXPORT_PDF_SELECTION:
			return {
				...state,
				exportPDFToggled: action.exportPDFToggled,
			};
		case actionTypes.ROUTE_VEHICLE_PAGE_SECTION:
			return {
				...state,
				pageSection: action.pageSection,
			};
		case actionTypes.GET_SERVICE_HISTORY:
			return {
				...state,
				serviceHistory: action.serviceHistory,
				dsbLoading: action.dsbLoading,
				dsbError: action.dsbError,
			};
		case actionTypes.GET_SERVICE_HISTORY_DETAIL:
			return {
				...state,
				serviceHistoryDetail: action.serviceHistoryDetail,
				serviceDetailLoading: action.serviceDetailLoading,
				serviceDetailError: action.serviceDetailError,
			};
		case actionTypes.GET_SERVICE_DOCUMENTATION:
			return {
				...state,
				isDistanceUnitMileage: action.isDistanceUnitMileage,
				serviceDocumentation: action.serviceDocumentation,
				serviceDocumentationLoading: action.serviceDocumentationLoading,
				serviceDocumentationError: action.serviceDocumentationError,
				serviceDocumentationCancelToken: action.serviceDocumentationCancelToken,
			};
		case actionTypes.TOGGLE_ALL_SERVICES:
			return {
				...state,
				showAllServiceRecords: action.showAllServiceRecords,
			};
		case actionTypes.SET_SCROLL_TO_KDM:
			return {
				...state,
				shouldScrollToKDM: action.shouldScrollToKDM,
			};
		case actionTypes.SET_SCROLL_TO_VEHICLE_ORDERS:
			return {
				...state,
				shouldScrollToVehicleOrders: action.shouldScrollToVehicleOrders,
			};
		case actionTypes.GET_DWD_OPTION:
			return {
				...state,
				isDWDAvailableForVehicle: action.isDWDAvailableForVehicle,
			};
		case actionTypes.UPDATE_EXPLOSION_DRAWING_HIGHLIGHTED_PARTS:
			return {
				...state,
				explosionDrawingHighlightedParts: action.explosionDrawingHighlightedParts,
			};
		case actionTypes.SET_VEHICLE_LIST_ACTIVE_SORTING_BUTTON:
			return {
				...state,
				vehicleListActiveSortingButton: action.vehicleListActiveSortingButton,
			};
		case actionTypes.SET_RECENT_ORDERS_FILTER_AVAILABLE:
			return {
				...state,
				recentOrderFilterAvailable: action.recentOrderFilterAvailable,
			};
		case actionTypes.SET_UPDATED_VEHICLE_NOTE:
			return {
				...state,
				updatedVehicleNote: action.updatedVehicleNote,
			};
		case actionTypes.GET_TO_BE_DELETED_VEHICLE_COUNT:
			return {
				...state,
				toBeDeletedVehicleCount: action.toBeDeletedVehicleCount,
			};
		case actionTypes.SET_DELETED_VEHICLE_VIN:
			return {
				...state,
				deletedVehicleVin: action.deletedVehicleVin,
			};
		case actionTypes.SET_RECENTLY_OPENED_VEHICLES:
			return {
				...state,
				recentlyOpenedVehicles: action.recentlyOpenedVehicles,
				recentlyOpenedVehiclesError: action.recentlyOpenedVehiclesError,
			};
		case actionTypes.GET_FILLING_TAB_DATA:
			return {
				...state,
				fillingsDependencyFailed: action.fillingsDependencyFailed,
				fillingsData: action.fillingsData,
			};
		case actionTypes.SELECT_AGGREGATE: {
			let aggSelections = [];
			if (!(action.selectedAggregate.aggregateType === AggregateTypes.VEHICLE)) {
				const aggregateIndex = state.vehicleDetails.vehicleInformation.aggregateSelections.findIndex(
					agg => agg.aggregateType === action.selectedAggregate.aggregateType
				);
				aggSelections = state.vehicleDetails.vehicleInformation.aggregateSelections;
				if (aggregateIndex === -1) {
					aggSelections.push(action.selectedAggregate);
				} else {
					aggSelections[aggregateIndex] = action.selectedAggregate;
				}
			} else {
				aggSelections.push(action.selectedAggregate);
			}

			return {
				...state,
				vehicleDetails: {
					...state.vehicleDetails,
					vehicleInformation: {
						...state.vehicleDetails.vehicleInformation,
						aggregateSelections: aggSelections,
					},
				},
			};
		}
		case actionTypes.GET_CATALOGS_WITH_VIN:
			return {
				...state,
				catalogs: action.catalogs,
				vehicleDataCardAvailable: action.vehicleDataCardAvailable,
			};
		case actionTypes.CLEAR_OLD_VEHICLE_REMARK:
			return {
				...state,
				oldVehicleRemark: false,
			};
		case actionTypes.SHOW_OLD_VEHICLE_REMARK:
			return {
				...state,
				oldVehicleRemark: true,
			};
		case actionTypes.SELECTED_CATALOG:
			return {
				...state,
				selectedCatalog: action.selectedCatalog,
			};
		case actionTypes.SET_ALL_SUPPLEMENTARY_PARTS:
			return {
				...state,
				allSupplementaryParts: action.allSupplementaryParts,
			};
		case actionTypes.SET_VEHICLE_CARD_OPENED:
			return {
				...state,
				isVehicleCardOpened: action.isVehicleCardOpened,
			};
		case actionTypes.GET_VEHICLE_NOTE:
			return {
				...state,
				vehicleNoteWithVin: action.vehicleNoteWithVin,
			};
		default:
			return state;
	}
};

export default vehicleReducer;
