import { VehicleMbSpecList, VehicleFillingDataType, ParsedVehicleFillings } from '@Constants/vehicle';

export const parseMbSpecs = (fillings: VehicleMbSpecList[]) => {
	const tempFillingsData: ParsedVehicleFillings = {
		engineOilData: undefined,
		breakFluidData: undefined,
		coolantData: undefined,
		transmissionOilData: undefined,
		rearaxleOilData: undefined,
		transfercaseOilData: undefined,
		steeringOilData: undefined,
		compressorOilData: undefined,
		refrigerantData: undefined,
		refrigeratorOilData: undefined,
		gearOilData: undefined,
		frontAxleGearOilData: undefined,
		rearAxleGearOilData: undefined,
		transferGearOilData: undefined,
	};
	const FILLINGS_TYPE_DATA_MAP = {
		[VehicleFillingDataType.ENGINE_OIL]: 'engineOilData',
		[VehicleFillingDataType.BRAKE_FLUID]: 'breakFluidData',
		[VehicleFillingDataType.COOLANT]: 'coolantData',
		[VehicleFillingDataType.TRANSMISSION_OIL]: 'transmissionOilData',
		[VehicleFillingDataType.REARAXLE_OIL]: 'rearaxleOilData',
		[VehicleFillingDataType.TRANSFERCASE_OIL]: 'transfercaseOilData',
		[VehicleFillingDataType.STEERING_OIL]: 'steeringOilData',
		[VehicleFillingDataType.COMPRESSOR_OIL]: 'compressorOilData',
		[VehicleFillingDataType.REFRIGERANT]: 'refrigerantData',
		[VehicleFillingDataType.REFRIGERATOR_OIL]: 'refrigeratorOilData',
		[VehicleFillingDataType.GEAR_OIL]: 'gearOilData',
		[VehicleFillingDataType.FRONT_AXLE_GEAR_OIL]: 'frontAxleGearOilData',
		[VehicleFillingDataType.REAR_AXLE_GEAR_OIL]: 'rearAxleGearOilData',
		[VehicleFillingDataType.TRANSFERCASE_GEAR_OIL]: 'transferGearOilData',
	};
	fillings.forEach((filling: VehicleMbSpecList) => {
		tempFillingsData[FILLINGS_TYPE_DATA_MAP[filling.type]] = filling.fillings.map(fillingItem => ({
			...fillingItem,
			type: filling.type,
		}));
	});

	return tempFillingsData;
};
