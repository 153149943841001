import requestData from '@Network/index';
import { unique } from '@Helpers/common/array';
import { AggregateSelection, LinasQueryType, PdfExportTypes, toVehicleDetails } from '@Constants/vehicle';
import { MIMETypes } from '@Constants/common';
import { SERIES_ID_SUBSTRING_LENGTH, initialCatalogueFilterOptionsState } from '@Constants/webparts';
import { FinalVehicleType } from '@Constants/product';

export function getDSBHealth() {
	return requestData('GET', 'DSB_HEALTH')
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}

export function getVehicleDetails(vinNumber) {
	return requestData('GET', 'VEHICLE_DETAILS', { interpolate: { vin: vinNumber } })
		.then(res => {
			return toVehicleDetails(res?.data);
		})
		.catch(err => {
			throw err;
		});
}

export function getNonSavedVehicleDetails(vinNumber) {
	return requestData('GET', 'VEHICLE_DETAILS_NON_SAVED', { interpolate: { vin: vinNumber } })
		.then(res => {
			return toVehicleDetails(res?.data);
		})
		.catch(err => {
			throw err;
		});
}

export function getVehicleDwdAvailability(vinNumber) {
	return requestData('GET', 'USER_VEHICLE_DWD', { suffix: vinNumber })
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}

export function submitVehicleRegistrationDate(vinNumber, registrationDate) {
	return requestData('POST', 'VEHICLE_REGISTRATION_DATE', {
		interpolate: { vinNumber },
		bodyData: { registrationDate },
	})
		.then(res => {
			return res.data;
		})
		.catch(err => {
			throw err;
		});
}

export function submitVehicleServiceDocumentation(vinNumber, dsbData) {
	return requestData('POST', 'DSB_CREATE_SERVICE', {
		interpolate: { vinNumber },
		bodyData: dsbData,
	})
		.then(res => {
			return res.data;
		})
		.catch(err => {
			throw err;
		});
}

export function updateVehicleServiceDocumentation(vinOrFinNumber, dsbData, serviceId) {
	return requestData('PUT', 'SERVICE_HISTORY_DETAIL', { interpolate: { vinOrFinNumber, serviceId }, bodyData: dsbData })
		.then(res => {
			return res.data;
		})
		.catch(err => {
			throw err;
		});
}

export function getDsbAdditionalInfo(vin, workshopCode, serviceType) {
	const data = {};
	if (serviceType) Object.assign(data, { serviceType });
	if (workshopCode) Object.assign(data, { workshopCode });
	return requestData('GET', 'DSB_ADDITIONAL_INFO', {
		interpolate: { vin },
		data,
	})
		.then(res => {
			return res.data;
		})
		.catch(err => {
			throw err;
		});
}

export function getDsbDetailedServiceInformation(serviceId, vinNumber, cancelToken) {
	return requestData('GET', 'DSB_DETAILED_SERVICE_INFORMATION', {
		interpolate: { vin: vinNumber },
		data: { serviceId },
		cancelToken,
	})
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}

export function getDSBExportedPDF(vin: string, serviceId: number[], language: string, printServiceHistory = false) {
	return requestData(
		'GET',
		'SERVICE_EXPORT_PDF',
		{
			interpolate: { vin },
			data: { serviceId, language, printServiceHistory },
			isFile: true,
		},
		MIMETypes.PDF
	)
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}

export function getKDMExportedPDFUrl(documentLink: string) {
	return requestData(
		'GET',
		PdfExportTypes.KDM_EXPORT_PDF,
		{
			interpolate: { documentLink },
		},
		MIMETypes.PDF
	)
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}

export function getKdmAvailabilityInfo(fins: string[] = []) {
	return requestData('GET', 'GET_KDM_AVAILABILITY', { params: { name: 'fins', values: fins } })
		.then(res => {
			return res.data;
		})
		.catch(err => {
			throw err;
		});
}

export function getKDMInfo(fin) {
	return requestData('GET', 'GET_KDM_INFO', { interpolate: { fin } })
		.then(res => {
			return res.data;
		})
		.catch(err => {
			throw err;
		});
}

export function exportKDMFile(incidentId, documentId) {
	return requestData('GET', 'EXPORT_KDM_FILE', { data: { incidentId, documentId }, isFile: true }, MIMETypes.PDF)
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}

export function getVehicleNote(vin) {
	return requestData('GET', 'GET_VEHICLE_NOTE', { interpolate: { vin } })
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}

export function updateVehicleNote(note, vin) {
	return requestData(note ? 'PATCH' : 'DELETE', 'UPDATE_VEHICLE_NOTE', {
		interpolate: { vin },
		...(note && { data: { note } }),
	})
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}

export function editVehicle(customerFullName, plateNumber, vin) {
	return requestData('PATCH', 'VEHICLE_DETAILS', {
		interpolate: { vin },
		data: { customerFullName, plateNumber },
	})
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}

export function getServiceHistory(vinOrFinNumber, numberServicesLastDocumented) {
	return requestData('GET', 'SERVICE_HISTORY', {
		interpolate: { vinOrFinNumber },
		...(numberServicesLastDocumented && { data: { numberServicesLastDocumented } }),
	})
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}

export function getServiceHistoryDetail(vinOrFinNumber, serviceId) {
	return requestData('GET', 'SERVICE_HISTORY_DETAIL', {
		interpolate: { vinOrFinNumber, serviceId },
	})
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}

export function deleteDSBService(serviceId, vinOrFinNumber) {
	return requestData('DELETE', 'SERVICE_HISTORY_DETAIL', { interpolate: { vinOrFinNumber, serviceId } })
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}

export function getServiceDocumentation(vinOrFinNumber, cancelToken) {
	return requestData('GET', 'SERVICE_DOCUMENTATION', {
		interpolate: { vinOrFinNumber },
		cancelToken,
	});
}

export function getRecentlyOpenedVehicles() {
	return requestData('GET', 'ORGANIZATION_VEHICLES_RECENTLY_OPENED')
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}

export function vehicleExistInOrganisation(vinNumber) {
	return requestData('GET', 'CHECK_VEHICLE_EXIST', { interpolate: { vin: vinNumber } })
		.then(res => {
			return res.data;
		})
		.catch(err => {
			throw err;
		});
}

export function getPartDetails({
	modelId,
	catalogProductId,
	productId,
	moduleId,
	sequenceId,
	fin = '',
	filterOptions = initialCatalogueFilterOptionsState,
}) {
	return requestData('GET', 'GET_PART_DETAILS', {
		data: {
			moduleId,
			sequenceId,
			fin,
			modelId,
			catalogProductId,
			productId,
			...filterOptions,
		},
	})
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}

export function getWebpartsCatalogueCategories(
	preferredLanguage,
	parameters,
	filterOptions = initialCatalogueFilterOptionsState
) {
	return requestData('GET', 'GET_PART_MAIN_GROUPS_WITH_CATEGORIES', {
		preferredLanguage,
		data: { ...parameters, ...filterOptions },
	})
		.then(res => {
			return res.data;
		})
		.catch(err => {
			throw err;
		});
}

export function getWebpartsCatalogueSubGroups(vinNumber, mainGroupId, data, preferredLanguage = '') {
	return requestData('GET', 'WEBPARTS_CATALOGUE_SUB_GROUPS', {
		interpolate: { vin: vinNumber, mainGroupId },
		data,
		preferredLanguage,
	})
		.then(res => {
			return res.data;
		})
		.catch(err => {
			throw err;
		});
}

export function getWebpartsCatalogueMaterialSubGroups(vinNumber, mainGroupId, productClassId, preferredLanguage = '') {
	return requestData('GET', 'WEBPARTS_CATALOGUE_MATERIAL_SUB_GROUPS', {
		interpolate: { vin: vinNumber, mainGroupId, productClassId },
		preferredLanguage,
	})
		.then(res => {
			return res.data;
		})
		.catch(err => {
			throw err;
		});
}

export function getWebpartsCatalogueRelatedParts(
	partNumber,
	vinNumber?,
	filterOptions = initialCatalogueFilterOptionsState,
	catalogProductId?,
	preferredLanguage = ''
) {
	return requestData('GET', 'WEBPARTS_CATALOGUE_RELATED_PARTS', {
		interpolate: { partNumber: partNumber.replace(/\s/g, '') },
		data: { fin: vinNumber, ...filterOptions, catalogProductId },
		preferredLanguage,
	})
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}

export function getWebpartsCatalogueSubGroupParts(
	mainGroupId,
	subGroupId,
	fin,
	modelId,
	productId,
	preferredLanguage,
	subGroupType,
	filterOptions = initialCatalogueFilterOptionsState,
	moduleId?
) {
	// TODO: When moduleId added to this endpoint add it to data before filterOption deconstruction
	return requestData('GET', 'GET_PART_SUB_GROUPS_PARTS', {
		interpolate: { mainGroupId, subGroupId },
		preferredLanguage,
		data: { subGroupType, fin, modelId, productId, ...filterOptions, moduleId },
	})
		.then(res => {
			return res.data;
		})
		.catch(err => {
			throw err;
		});
}

export function getWebpartsCatalogueSubGroupPartsV2(
	mainGroupId,
	subGroupId,
	fin,
	modelId, // TODO: Aggregate will be added AggregateModelId
	productId, // TODO: Aggregate will be added AggregateProductId
	catalogProductId,
	preferredLanguage,
	subGroupType,
	filterOptions = initialCatalogueFilterOptionsState,
	moduleId?
) {
	// TODO: When moduleId added to this endpoint add it to data before filterOption deconstruction
	return requestData('GET', 'GET_PART_SUB_GROUPS_PARTS', {
		interpolate: { mainGroupId, subGroupId },
		preferredLanguage,
		data: { subGroupType, fin, modelId, productId, catalogProductId, ...filterOptions, moduleId },
	})
		.then(res => {
			return res.data;
		})
		.catch(err => {
			throw err;
		});
}

export function getWebpartsCatalogueMaterialSubGroupParts(
	vin: string,
	mainGroupId,
	subGroupId,
	productClassId,
	preferredLanguage = ''
) {
	return requestData('GET', 'WEBPARTS_CATALOGUE_MATERIAL_SUB_GROUP_PARTS', {
		interpolate: { vin, mainGroupId, subGroupId, productClassId },
		preferredLanguage,
	})
		.then(res => {
			return res.data;
		})
		.catch(err => {
			throw err;
		});
}

export type FinOrModelId =
	| {
			fin: string;
			modelId?: never; // Ensure `modelId` is not provided
	  }
	| {
			modelId: string;
			fin?: never; // Ensure `fin` is not provided
	  };

export function getMaterialPartMainGroups(identifier: FinOrModelId, preferredLanguage: string) {
	return requestData('GET', 'GET_MATERIAL_PART_MAIN_GROUPS', {
		preferredLanguage,
		data: { ...identifier },
	})
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}

export function getMaterialPartSubGroups(mainGroupId: string, productClassId: string, preferredLanguage: string) {
	return requestData('GET', 'GET_MATERIAL_PART_SUB_GROUPS', {
		preferredLanguage,
		interpolate: { mainGroupId },
		data: { productClassId },
	})
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}

export function getMaterialParts(
	mainGroupId: string,
	subGroupId: string,
	productClassId: string,
	preferredLanguage: string
) {
	return requestData('GET', 'GET_MATERIAL_PARTS', {
		interpolate: { mainGroupId, subGroupId },
		data: { productClassId },
		preferredLanguage,
	})
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}

export function getMaterialPartDetails(moduleId: string, sequenceId: string, preferredLanguage: string) {
	return requestData('GET', 'GET_MATERIAL_PART_DETAILS', {
		data: { moduleId, sequenceId },
		preferredLanguage,
	})
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}

export function getLinasSearchTermSuggestions(vin, searchTerm) {
	return requestData('GET', 'LINAS_SEARCH_SUGGESTION', {
		interpolate: { vin },
		data: { searchTerm },
	})
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}

export function getLinasResults(
	vin,
	searchTerms,
	filterXot,
	filterWis,
	filterParts,
	queryType: LinasQueryType,
	vehicleDivision: FinalVehicleType
) {
	return requestData('POST', 'LINAS_SEARCH', {
		interpolate: { vin },
		// data: { pageSize, pageNumber },
		bodyData: { searchTerms, filterXot, filterWis, filterParts, queryType, vehicleDivision },
	})
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}

export function getLinasRecentQueries(vin) {
	return requestData('GET', 'LINAS_RECENT', {
		interpolate: { vin },
	})
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}

export function getPartFastSearchWithoutContext(searchingText, preferredLanguage) {
	return requestData('GET', 'GET_PART_FAST_SEARCH_WITHOUT_CONTEXT', {
		data: { text: searchingText },
		preferredLanguage,
	})
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}

export function getPartSearchDetails(
	searchingText,
	seriesId,
	fin = '',
	preferredLanguage = '',
	modelId = '',
	limit: number = null
) {
	// TODO: Remove after analysis
	return requestData('GET', 'GET_PART_SEARCH_DETAILS', {
		data: {
			text: searchingText,
			modelRange: seriesId,
			...(fin && { fin }),
			...(modelId && { modelId }),
			...(limit && { limit }),
		},
		preferredLanguage,
	})
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}

export function getVehicleConsentInfo() {
	return requestData('GET', 'CHECK_VEHICLE_CONSENTS')
		.then(res => {
			return res.data;
		})
		.catch(err => {
			throw err;
		});
}

export function getNextServiceInfos(fins) {
	return requestData('GET', 'VEHICLE_NEXT_SERVICES', { params: { name: 'fins', values: fins } })
		.then(res => {
			return res.data;
		})
		.catch(err => {
			throw err;
		});
}

export function getFillingData(fin) {
	return requestData('GET', 'VEHICLE_FILLINGS', {
		interpolate: { fin },
	})
		.then(res => {
			return res.data;
		})
		.catch(err => {
			throw err;
		});
}

export function getOrderStatuses(fins) {
	return requestData('GET', 'VEHICLE_ORDER_STATUSES', { params: { name: 'fins', values: fins } })
		.then(res => {
			return res.data;
		})
		.catch(err => {
			throw err;
		});
}

export function keepToBeDeletedVehicle(vin) {
	return requestData('PATCH', 'VEHICLE_KEEP_TO_BE_DELETED', { interpolate: { vin } })
		.then(res => {
			return res.data;
		})
		.catch(err => {
			throw err;
		});
}

export function getToBeDeletedVehicleCount() {
	return requestData('GET', 'VEHICLE_TO_BE_DELETED_COUNT')
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}

export function getVehicleLicensePlateVINMapping(licensePlate: string) {
	return requestData('GET', 'VEHICLE_LICENSE_PLATE_VIN_MAPPING', { data: { licensePlate } })
		.then(res => {
			return res.data;
		})
		.catch(err => {
			throw err;
		});
}

export function validateOrganizationVehicle(vin: string) {
	return requestData('GET', 'ORGANIZATION_VEHICLE_VALIDATION', { data: { vin } })
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}

export function getVehicleFOCodes(vin: string) {
	const trimmedVIN = vin.replace(/\s/g, '');
	return requestData('GET', 'VEHICLE_SPECIAL_MODIFICATION_CHECK', { interpolate: { vin: trimmedVIN } })
		.then(res => {
			return res;
		})
		.catch(() => {
			return [];
		});
}

export function getCatalogsWithVIN(vin: string) {
	return requestData('GET', 'AGGREGATES', { interpolate: { vin } })
		.then(res => {
			return res;
		})
		.catch(() => {
			return [];
		});
}

export function selectAggregate(vin: string, aggregateSelection: AggregateSelection) {
	return requestData('PATCH', 'AGGREGATE_SELECTION', { interpolate: { vin }, bodyData: aggregateSelection })
		.then(res => {
			return res;
		})
		.catch(() => {
			return [];
		});
}

export function getSearchedTextSeriesIds(searchingText, preferredLanguage = '') {
	return requestData('GET', 'GET_PART_FAST_SEARCH_WITHOUT_CONTEXT', {
		data: { text: searchingText },
		preferredLanguage,
	})
		.then(res => {
			const seriesIds = [];
			if (res.data && res.data.resultList) {
				res.data.resultList.forEach(item => {
					seriesIds.push(item.modelRange.substring(0, SERIES_ID_SUBSTRING_LENGTH));
				});
			}
			return unique(seriesIds);
		})
		.catch(err => {
			throw err;
		});
}

export function getPPVCredits() {
	return requestData('GET', 'PAY_PER_VIN_PACKAGES_CREDITS')
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}

export function getPPVLicenseForVIN(vin) {
	return requestData('GET', 'PAY_PER_VIN_LICENCES', { interpolate: { vin } })
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}

export function getVehiclePPVStates(vins) {
	return requestData('GET', 'VEHICLE_PPV_STATES', { params: { name: 'vins', values: vins } })
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}

export function unlockPPVLicenseForVIN(vin) {
	return requestData('POST', 'UNLOCK_PAY_PER_VIN_LICENCES', {
		bodyData: { vin },
	})
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}
