import Link from 'next/link';
import ISPLink from '@isp/link';

const InternalLink = ({ children, href, as, shallow = false, shouldScroll = true, ...props }) => (
	<Link href={href} as={as} shallow={shallow} legacyBehavior scroll={shouldScroll}>
		<ISPLink destination={as} {...props}>
			{children}
		</ISPLink>
	</Link>
);

export default InternalLink;
