import { actionTypes } from '@Reducers/configuration/actionTypes';
import { ApplicationSettingsType, FeatureConfigurationType, PriceConfigurationType } from './models';

export type ConfigurationState = {
	language: string;
	applicationSettings: ApplicationSettingsType;
	languageConfiguration: {
		defaultLanguage: string;
		availableLanguages: string[];
	};
	sectionConfiguration: {};
	featureConfiguration: FeatureConfigurationType;
	priceConfiguration: PriceConfigurationType;
	productContactEmail: string;
	market: {
		code: string;
		name: string;
		vegaTenantNo: string;
		isRow: boolean;
		countryIsoCode: string;
	};
	unavailablePartOrderTypes: string[];
};

export const initialConfigurationState: ConfigurationState = {
	language: '',
	applicationSettings: null,
	languageConfiguration: {
		defaultLanguage: '',
		availableLanguages: [],
	},
	priceConfiguration: null,
	sectionConfiguration: {},
	featureConfiguration: null,
	productContactEmail: '',
	market: null,
	unavailablePartOrderTypes: [],
};

export const configurationReducer = (state = initialConfigurationState, action) => {
	if (action.type === actionTypes.CHANGE_LANGUAGE) {
		return {
			...state,
			language: action.language,
			priceConfiguration: action.priceConfiguration,
		};
	}
	return state;
};

export default configurationReducer;
