import { TermsOfUse } from '@Constants/common';
import { FinalVehicleType, SubscriptionTypes } from '@Constants/product';

export interface VehicleLicense {
	productKey: string;
	hasActiveLicense: boolean;
	expireTime: number;
}
export interface RmiLicenseType {
	productKey: string;
	hasActiveLicense: boolean;
	vehicleTypes: string[];
	expireTime: number;
	vehicleLicenses: VehicleLicense[];
}

export interface DiagnosisLicenseType {
	expireTime: number;
	productKey: string;
	passThruSystems: System[];
}

export interface UserActionsType {
	LOGOUT: Function;
	GET_LICENSES: Function;
	GET_USER_INFO: Function;
	GET_DIGITAL_SERVICE_BOOKLET_STATUS: Function;
	UPDATE_TERMS_OF_USE_STATUS: Function;
	GET_REPUBLISHER_REQUEST_STATUS: Function;
	REQUEST_REPUBLISHER_ROLE: Function;
	TOGGLE_SHOW_TERMS_OF_USE_MODAL: Function;
	GET_SATISFACTION_FEEDBACK_SCORE_USER_ENABLE: Function;
}

export interface UserInfo {
	userId: string;
	firstName: string;
	lastName: string;
	role: string[];
	isFirstLogin: boolean;
	isUserLoggedIn: boolean;
	country: string;
	crispId: string;
	organizationId: string;
	userType: string;
	email: string;
	termsOfUse: TermsOfUse;
	isTermsOfUseAccepted: boolean;
	loginError;
	phone?: string;
	loginTriggered?: boolean;
	oneDoCData?: OneDoCData;
	isNewAdded?: boolean;
	isASPUser?: boolean;
}

export interface DigitalServiceBooklet {
	productKey: string;
	hasActiveLicense: boolean;
	vehicleTypes: string[];
	expireTime: number;
	serviceError?: boolean;
	loading?: boolean;
}

export interface OrganizationUser {
	gssnId: string;
	organizationId: string;
	organizationName: string;
	userList: UserInfo[];
}

export interface System {
	systemId: string;
	expireTime: number;
	hardwareId: string;
	timeCredits: TimeCredit[];
}

export interface TimeCredit {
	productKey: FinalVehicleType;
	creditsLeft: number;
	creditType: SubscriptionTypes;
	activityStatus: number;
}

export interface OneDoCData {
	firstName: string;
	lastName: string;
	email: string;
	phone: string;
}

export interface PersonalSettings {
	useShowNetPriceShortcut: boolean;
	isVehiclePartsListView: boolean;
	selectedPartLanguage: string;
	contentLanguages: string[];
	selectedWebPartsOrderType: string;
	selectedDeliveryType: string;
}

export const toVehicleLicense = data => ({
	productKey: data?.productKey || '',
	hasActiveLicense: data?.hasActiveLicense || false,
	expireTime: data?.expireTime || null,
});

export const toRmiLicense = license => ({
	productKey: license?.productKey || '',
	hasActiveLicense: license?.hasActiveLicense || false,
	vehicleTypes: license?.vehicleTypes || [],
	expireTime: license?.expireTime || null,
	vehicleLicenses: license?.vehicleLicenses?.map(vehicleLicense => toVehicleLicense(vehicleLicense)),
});

export const toTimeCredit = credit => ({
	productKey: credit?.productKey || '',
	creditsLeft: credit?.creditsLeft || null,
	creditType: credit?.creditType || '',
	activityStatus: credit?.activityStatus || null,
});

export const toSystem = data => ({
	systemId: data?.systemId || '',
	expireTime: data?.expireTime || null,
	hardwareId: data?.hardwareId || '',
	timeCredits: data?.timeCredits.map(item => toTimeCredit(item)) || [],
});

export const toDiagnosisLicense = license => ({
	expireTime: license?.expireTime || null,
	productKey: license?.productKey || '',
	passThruSystems: license?.passThruSystems.map(item => toSystem(item)) || [],
});
