import { actionTypes } from '@Reducers/faq/actionTypes';

export const initialFAQState = {
	productRoutes: [],
};

export const faqReducer = (state = initialFAQState, action) => {
	switch (action.type) {
		case actionTypes.GET_PRODUCT_ROUTES:
			return {
				...state,
				productRoutes: action.productRoutes,
			};
		default:
			return state;
	}
};

export default faqReducer;
