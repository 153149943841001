import routes from '@Routes';
import getConfig from 'next/config';

export const UNAVAILABLE_COUNTRY = -1;
export const ES1_KEY_LENGTH = 2;
export const TOOLTIP_DELAY_TIME = 100;
export const SCROLL_TRIGGER_DELAY_TIME = 100;
export const FOCUSOUT_DELAY_DURATION = 200;
export const COLLAPSIBLE_SCROLL_DELAY = 300;
export const VIN_LENGTH = 17;
export const BASE64_PREFIX = 'data:image/png;base64,';
export const ATTACHMENT_KB_RATE = 1024;
export const SIX_DIGIT_COUNT = 6;
export const SIDEBAR_EXPAND_LIMIT = 1280;
export const MQ4_WIDTH_MAX = 1280;

export const PERCENTAGE = 100;
export const MS_IN_A_WEEK = 604800;
export const MS_IN_SEC = 1000;
export const MINUTES_IN_AN_HOUR = 60;
export const SECONDS_IN_A_MINUTE = 60;
export const MS_IN_A_MINUTE = SECONDS_IN_A_MINUTE * MS_IN_SEC;
export const MS_IN_AN_HOUR = MINUTES_IN_AN_HOUR * SECONDS_IN_A_MINUTE * MS_IN_SEC;
export const HOURS_IN_A_DAY = 24;
export const MS_IN_A_DAY = HOURS_IN_A_DAY * MS_IN_AN_HOUR;
export const MONTH_IN_YEAR = 12;
export const STICKY_TOOLBAR_HEIGHT = 60;
export const NOTIFICATION_POSITION = 65;
export const MONTH_COUNT = 12;

export const DMY_FORMAT = 'DD.MM.YYYY';
export const DMY_SHORT_FORMAT = 'DD.MM.YY';
export const DMY_SLASHED_FORMAT = 'DD/MM/YYYY';
export const DMY_LONG_FORMAT = 'DD MMMM YYYY';
export const MY_FORMAT = 'MMMM YYYY';
export const YMD_TIME_FORMAT = 'YYYY/MM/DD HH:mm:ss';
export const MDY_FORMAT = 'MMM D, YYYY';
export const Y_FORMAT = 'YYYY';
export const M_FORMAT = 'MMMM';
export const DD_FORMAT = 'DD';
export const HHMM_FORMAT = 'HH:mm';

export enum Months {
	JAN = 1,
	FEB,
	MAR,
	APR,
	MAY,
	JUN,
	JUL,
	AUG,
	SEP,
	OCT,
	NOV,
	DEC,
}

export const MAP_TO_MONTH_NAME = {
	[Months.JAN]: 'common:abr-january',
	[Months.FEB]: 'common:abr-february',
	[Months.MAR]: 'common:abr-march',
	[Months.APR]: 'common:abr-april',
	[Months.MAY]: 'common:abr-may',
	[Months.JUN]: 'common:abr-june',
	[Months.JUL]: 'common:abr-july',
	[Months.AUG]: 'common:abr-august',
	[Months.SEP]: 'common:abr-september',
	[Months.OCT]: 'common:abr-october',
	[Months.NOV]: 'common:abr-november',
	[Months.DEC]: 'common:abr-december',
};

export const DEALER_LOCATOR_SEARCH_PROFILE_NAME = 'B2BConnect_WebParts';
export const DEALER_LOCATOR_ELEMENT_FOR_DWS = 'dl-dsw';

export const VIDEO_EXTENSIONS = ['mp4', 'ogg', 'webm'];

export const NEW_FEATURE_MODAL_REMIND_LATER_INTERVAL = 4;

const { publicRuntimeConfig } = getConfig();
export const APP_VERSION = publicRuntimeConfig.VERSION || '';

export enum HTTPHeader {
	CACHE_CONTROL = 'Cache-Control',
	ACCEPT_LANGUAGE = 'Accept-Language',
	CONTENT_TYPE = 'Content-Type',
	MODIFIED_SINCE = 'If-Modified-Since',
	AUTHORIZATION = 'Authorization',
}

export enum CustomHTTPHeader {
	FALLBACK_USED = 'X-Fallback-Used',
	MARKET_CODE = 'Market-Code',
	TIMEZONE = 'Time-Zone',
}

export enum ERROR_CODES {
	REQUEST_TIMEOUT = 'ECONNABORTED',
}

export const HTTP_METHODS_WITH_QUERY_PARAMS = ['GET', 'DELETE', 'POST', 'PUT'];

export enum HTTPResponseCodes {
	NO_CONTENT_SUCCESS = 204,
	PERMANENTLY_MOVED = 301,
	FOUND = 302,
	BAD_REQUEST = 400,
	UNAUTHORIZED = 401,
	FORBIDDEN = 403,
	NOT_FOUND = 404,
	CONFLICT = 409,
	UNPROCESSABLE_ENTITY = 422,
	FAILED_DEPENDENCY = 424,
	INTERNAL_SERVER = 500,
	SERVICE_UNAVAILABLE = 503,
}

export enum MaskPatterns {
	DATE = 'd{.}`m{.}`Y',
}

export enum Patterns {
	VIN = '^[A-HJ-NPR-Za-hj-npr-z/i]{3}[0-9A-HJ-NPR-Za-hj-npr-z/i]{6}[0-9A-HJ-NPR-Za-hj-npr-z/d]{8}$',
	VIN_LENGTH_PATTERN = '^[A-Za-z0-9]{17}$',
	PART_NUMBER = '^(B6|[A,N,Q,H,W,X,R,U]{1}[0-9\\s]{1})[A-Za-z0-9\\s]{9,24}$',
	NAME_OR_SURNAME = '^[a-zA-ZÀ-ÖØ-öø-ÿ\\s]+$',
	INTERNAL_URL = '(http|https|mailto)',
	VEHICLE_REGISTRATION_NUMBER = '^[0-9-_a-zA-ZÀ-ÖØ-öø-ÿ\\s]+$',
	PRODUCTS_PAGE = '(/products/)',
	NONE = '.*',
	EMAIL = '^[a-zA-Z0-9_+&*-]+(?:\\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,7}$',
	PRODUCT_ID = '^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$',
	HARDWARE_ID = '^[A-F0-9]{12}$',
	MAX_80_CHARS_PATTERN = '^.{1,80}$',
	PUNCTUATION_FROM_START = '[!"#$%&\'()*+,-.:;<=>?@[\\]^_`{|}~]*$',
	PUNCTUATION_FROM_END = '[[!"#$%&\'()*+,-.:;<=>?@[\\]^_`{|}~]*]*',
	SIX_DIGIT_CODE = '^[0-9]{6}$',
	SEVEN_DIGIT_CODE = '^[0-9]{7}$',
	URL = '^https?://',
	HTML_TAGS = '\\<.*?\\>',
}

export const StringReplacePatterns = {
	REGEX_REMOVE_SPACES_PUNCTUATION: /[^\w]|_/g,
	REGEX_REPLACE_ALL_GLOBAL: 'g',
};

export const NumberPatterns = {
	SEPERATE_THOUSANDS_GLOBAL: /\B(?=(\d{3})+(?!\d))/g,
	REMOVE_NON_NUMBERS_GLOBAL: /\D/g,
	REGEX_SPLIT_ES2_KEYS_FROM_NO: /NO\.(\d+)/g,
	NUMERIC_CHARACTERS: /\d/g,
};

// eslint-disable-next-line max-len
export const USER_AGENT_TOUCH_DEVICES = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/;

export enum RouterEvents {
	START = 'routeChangeStart',
	COMPLETE = 'routeChangeComplete',
	ERROR = 'routeChangeError',
}

export enum ScrollEvents {
	DOWN = 'scrollDown',
	UP = 'scrollUp',
}

export enum ScrollIntoViewOptions {
	START = 'start',
	CENTER = 'center',
	END = 'end',
	NEAREST = 'nearest',
}

export enum ArrowDirection {
	NEXT,
	PREV,
}

export enum RowCountries {
	CA = 'ca',
	CN = 'cn',
	HK = 'hk',
	TR = 'tr',
	ZA = 'za',
	AU = 'au',
	TW = 'tw',
	KR = 'kr',
}

export enum TermsOfUseStatus {
	NOT_ACCEPTED = 'NOT_ACCEPTED',
	ACCEPTED = 'ACCEPTED',
	POSTPONED = 'POSTPONED',
	FORCE_ACCEPT = 'FORCE_ACCEPT',
}

export interface TermsOfUse {
	status: TermsOfUseStatus;
	version: string;
	expirationDate: string;
}

export enum Environments {
	LOCAL = 'LOCAL',
	DEV = 'DEV',
	PROD = 'PROD',
	NON_PROD = 'NON_PROD',
}
export const SCROLL_DISABLED_PAGES = [
	routes.FAQ_CATEGORY,
	routes.NAVIGATION_CATEGORIES,
	routes.CATALOGUE,
	routes.CATALOGUE_PARTS,
];

export enum ResponseCode {
	SUCCESS = 'SUCCESS',
	INVALID_DATA = 'INVALID_DATA',
	NOT_SAVED = 'NOT_SAVED',
	CONFLICT = 'CONFLICT',
	NOT_FOUND = 'NOT_FOUND',
	UNAUTHORIZED = 'UNAUTHORIZED',
	DB_ERROR = 'DB_ERROR',
	ERR_BAD_REQUEST = 'ERR_BAD_REQUEST',
	EXTERNAL_API_EXCEPTION = 'EXTERNAL_API_EXCEPTION',
	INTERNAL_API_EXCEPTION = 'INTERNAL_API_EXCEPTION',
	FORBIDDEN = 'FORBIDDEN',
	API_JWT_PARSE_EXCEPTION = 'API_JWT_PARSE_EXCEPTION',
	BAD_REQUEST = 'BAD_REQUEST',
	NOT_REGISTERED_DEALER = 'NOT_REGISTERED_DEALER',
	MISSING_EMAIL_FOR_CUSTOMER_COMPANY = 'MISSING_EMAIL_FOR_CUSTOMER_COMPANY',
	CUSTOMER_COMPANY_NOT_IN_GEMS = 'CUSTOMER_COMPANY_NOT_IN_GEMS',
	CUSTOMER_USER_NOT_IN_GEMS = 'CUSTOMER_USER_NOT_IN_GEMS',
	CUSTOMER_USER_NOT_ASSSIGNED_CUSTOMER_IN_GEMS = 'CUSTOMER_USER_NOT_ASSSIGNED_CUSTOMER_IN_GEMS',
	ARVATO_ORDERS_FAILED = 'ARVATO_ORDERS_FAILED',
	WEBPARTS_ORDERS_FAILED = 'WEBPARTS_ORDERS_FAILED',
	PREFERRED_DEALER_NOT_FOUND = 'PREFERRED_DEALER_NOT_FOUND',
	DATACARD_NOT_FOUND = 'DATACARD_NOT_FOUND',
}

export enum FeatureKeys {
	WEBPARTS = 'webParts',
	WHEELS = 'wheels',
	TIRES = 'tires',
	GYOW = 'gyow',
	XENTRY_SHOP = 'xentryShop',
	STANDARDISED_NAVIGATION = 'standardisedNavigation',
	DSB = 'dsb',
	DEALER_LOCATOR = 'dealerLocator',
	DISCLAIMER_BOX = 'disclaimerBox',
	DWD = 'dwd',
	PARTS_GOAL = 'partsGoal',
	ARVATO_BLOCKED = 'isArvatoBlocked',
	ONE_DOC = 'oneDoc',
	TECHNICAL_DATA = 'technicalData',
	KDM = 'kdm',
	REMAN = 'remanEngine',
	LINAS = 'linas',
	REMAN_PRICE_UNIFIED = 'isRemanPriceDepositUnified',
	LICENSES = 'licenses',
}

export const RedirectionKeys = {
	LI: 'CH',
	MQ: 'FR',
	RE: 'FR',
	GY: 'FR',
	YT: 'FR',
	GP: 'FR',
	BL: 'FR',
};

export enum MetaSettingPages {
	HOME = 'HOME',
	STANDARDISED_NAVIGATION = 'STANDARDISED_NAVIGATION',
	FAQ_HELP = 'FAQ_HELP',
	SITEMAP = 'SITEMAP',
	NEWS = 'NEWS',
	LEGAL = 'LEGAL',
	PROVIDER = 'PROVIDER',
	PRIVACY_STATEMENT = 'PRIVACY_STATEMENT',
	LEGAL_NOTICE = 'LEGAL_NOTICE',
	TERMS_OF_USE = 'TERMS_OF_USE',
	EQUIPMENT = 'EQUIPMENT',
}

export enum MIMETypes {
	PDF = 'application/pdf',
	JSON = 'application/json',
	JAVA_SCRIPT = 'application/javascript',
	CSS = 'text/css',
	MULTIPART = 'multipart/form-data',
	IMAGE_PNG = 'image/png',
	XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}

export enum FileExtensions {
	XLSX = '.xlsx',
	XFR = '.xfr',
	PDF = '.pdf',
}

export enum RegistrationEvents {
	QUIT = 'REGISTRATION_QUIT',
	COMPLETE = 'REGISTRATION_COMPLETE',
}

export enum WindowTargetAttributes {
	BLANK = '_blank',
	PARENT = '_parent',
	SELF = '_self',
	TOP = '_top	',
}

export enum Events {
	CLICK = 'click',
	FOCUS = 'focus',
	INPUT = 'input',
	KEY_DOWN = 'keydown',
	LANGUAGE_CHANGED = 'languageChanged',
	MOUSE_DOWN = 'mousedown',
	MOUSE_OUT = 'mouseout',
	MOUSE_OVER = 'mouseover',
	MESSAGE = 'message',
	PPV_UNLOCKED_VIN = 'ppvUnlockedVin',
	RESIZE = 'resize',
	SCROLL = 'scroll',
	STORAGE_UPDATED = 'storageUpdated',
	TOUCH_START = 'touchstart',
	TRANSITIONED = 'transitionend',
	DSB_LOAD = 'dsbload',
}

export enum CustomEvents {
	DSB_LOAD = 'dsbLoad',
	SIDEBAR_HEIGHT_CHANGE = 'sidebarHeightChange',
	KEEP_VEHICLE_SUCCEED = 'keepVehicleSucceed',
	VEHICLE_CARD_HEIGHT_CHANGE = 'vehicleCardHeightChange',
}

export enum IframeSandboxParams {
	ALLOW_SCRIPTS = 'allow-scripts',
	ALLOW_SAME_ORIGIN = 'allow-same-origin',
	ALLOW_TOP_NAVIGATION = 'allow-top-navigation',
	ALLOW_FORMS = 'allow-forms',
	ALLOW_MODALS = 'allow-modals',
	ALLOW_ORIENTATION_LOCK = 'allow-orientation-lock',
	ALLOW_POPUPS = 'allow-popups',
	ALLOW_POINTER_LOCK = 'allow-pointer-lock',
	ALLOW_POPUPS_TO_ESCAPE_SANDBOX = 'allow-popups-to-escape-sandbox',
	ALLOW_PRESENTATION = 'allow-presentation',
	ALLOW_TOP_NAVIGATION_BY_USER_ACTIVATION = 'allow-top-navigation-by-user-activation',
}

export const IFRAME_SANDBOX_PARAMS_ALL_ALLOWED = [
	IframeSandboxParams.ALLOW_FORMS,
	IframeSandboxParams.ALLOW_MODALS,
	IframeSandboxParams.ALLOW_ORIENTATION_LOCK,
	IframeSandboxParams.ALLOW_POINTER_LOCK,
	IframeSandboxParams.ALLOW_POPUPS,
	IframeSandboxParams.ALLOW_POPUPS_TO_ESCAPE_SANDBOX,
	IframeSandboxParams.ALLOW_PRESENTATION,
	IframeSandboxParams.ALLOW_SAME_ORIGIN,
	IframeSandboxParams.ALLOW_SCRIPTS,
	IframeSandboxParams.ALLOW_TOP_NAVIGATION,
	IframeSandboxParams.ALLOW_TOP_NAVIGATION_BY_USER_ACTIVATION,
];

export enum ByteUnits {
	BYTE = 'B',
	KILOBYTE = 'KB',
	MEGABYTE = 'MB',
	GIGABYTE = 'GB',
	TERABYTE = 'TB',
	PETABYTE = 'PB',
}

export const ALL_BYTE_UNITS = [
	ByteUnits.BYTE,
	ByteUnits.KILOBYTE,
	ByteUnits.MEGABYTE,
	ByteUnits.GIGABYTE,
	ByteUnits.TERABYTE,
	ByteUnits.PETABYTE,
];

export enum LayoutBackgrounds {
	WHITE = 'bg-white',
	WHITE_TWO = 'bg-white-two',
	WHITE_SMOKE = 'bg-white-smoke',
}

export const COLLAPSE_EXCLUDED_TABLE_CLASSES = ['filterable-table__table'];

export enum MeasurementUnits {
	LITER = 'l',
}

export enum InputTypes {
	FILE = 'file',
}

export enum PromiseStatus {
	REJECTED = 'rejected',
	FULFILLED = 'fulfilled',
}

export enum ScrollAligmentParams {
	START = 'start',
	CENTER = 'center',
	END = 'end',
	NEAREST = 'nearest',
}

export enum SearchHistoryTypes {
	HOME = 'Home',
	LINAS = 'Linas',
	FAQ = 'Faq',
	PARTS = 'Parts',
	PARTS_WITH_VIN = 'PartsWithVin',
	REMAN = 'Reman',
	EQUIPMENT = 'Equipment',
	VIN_FOR_PARTS = 'VinForParts',
	VIN_OR_MODELID_FOR_PARTS = 'VinOrModelIdForParts',
}
