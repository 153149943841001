import isNil from 'lodash/isNil';
import { getOrCreateStore } from '@Lib/with-redux-store';
import { PushDataToTagManagerForExternalUrls } from '@Helpers/google-analytics/pushDataToTagManager';
import { Router } from '@Lib/i18n';
import serviceEndpoints from '@Service-endpoints';
import { ProductKeys } from '@Constants/product';
import { WindowTargetAttributes } from '@Constants/common';
import {
	APPLICATION_UTM_SOURCE_ID,
	TagManagerEventKeys,
	UTM_TRAFFIC_TYPE,
	UtmParameters,
} from '@Constants/google-analytics';
import { createEmailLink } from './QueryString';
import getAppEnvironment from './common/getAppEnvironment';
import { getRetailersStates } from './retailer/GetRetailersStates';

export enum ExternalURLKeys {
	XENTRY_DIAGNOSIS_URL = 'xentryDiagnosisUrl',
	DSB_ACCESS = 'dsbAccess',
	RMI_WIS = 'rmiWis',
	RMI_ASRA = 'rmiAsra',
	RMI_PARTS_INFORMATION = 'rmiPartsInformation',
	RMI_XENTRY_TIPS = 'rmiXentryTips',
	WEBPARTS = 'webparts',
	REGISTRATION = 'registrationUrl',
	UMAS = 'umasMicrofrontendUrl',
	FORGOT_PASSWORD_URL = 'forgotPasswordUrl',
	DYNAMIC_WIRING_URL = 'dynamicWiringUrl',
	WEBPARTS_MAIN_PAGE = 'webpartsMainPage',
	ONE_DOC_LEGAGL_ENTITY = 'oneDocLegalEntity',
	CONSENT_REPONSIBLE_MAIL_ADDRESS = 'consentsResponsibleMailAddress',
	IPARTS_MEDIA_URL = 'ipartsMediaUrl',
	UMAS_ADMIN_RIGHTS_URL = 'umasAdminRightsUrl',
	UMAS_COMPANY_MANAGEMENT = 'umasCompanyManagementUrl',
	UMAS_USER_MANAGEMENT = 'umasUserManagementUrl',
	WEBPARTS_WHEELS_URL = 'webpartsWheelsUrl',
	WHEEL_ALIGNMENT_ONLINE_URL = 'waoUrl',
	XENTRY_SUPPORT_URL = 'xentrySupportUrl',
}

export const TRACKABLE_RMI_PRODUCTS = [
	ProductKeys.WIS,
	ProductKeys.PARTS_INFORMATION,
	ProductKeys.ASRA,
	ProductKeys.XENTRY_TIPS,
];

export const URLS = {
	[ProductKeys.WIS]: 'RMI_WIS',
	[ProductKeys.ASRA]: 'RMI_ASRA',
	[ProductKeys.PARTS_INFORMATION]: 'RMI_PARTS_INFORMATION',
	[ProductKeys.XENTRY_TIPS]: 'RMI_XENTRY_TIPS',
	[ProductKeys.DIGITAL_SERVICE_BOOKLET]: 'DSB_ACCESS',
	[ProductKeys.DWD]: 'DYNAMIC_WIRING_URL',
	[ProductKeys.WAO]: 'WHEEL_ALIGNMENT_ONLINE_URL',
};

export function trackingUrlBuilder(baseURL: string, source: string, type: string, queryParam = false) {
	return `${baseURL}${queryParam ? '&' : '?'}${UtmParameters.SOURCE}=${source}&${UtmParameters.TYPE}=${type}`;
}

export function GetExternalURL(urlKey) {
	const store = getOrCreateStore({});
	const { applicationSettings } = store.getState().configuration;

	return isNil(applicationSettings) ? '' : applicationSettings[urlKey] || '';
}

export function GoToExternalURL(urlKey, trackable = false) {
	const store = getOrCreateStore({});
	const { applicationSettings } = store.getState().configuration;

	if (!isNil(applicationSettings)) {
		const baseUrl = applicationSettings[ExternalURLKeys[urlKey]];
		const url = trackable ? trackingUrlBuilder(baseUrl, APPLICATION_UTM_SOURCE_ID, UTM_TRAFFIC_TYPE) || '' : baseUrl;
		window.open(url, WindowTargetAttributes.BLANK);
		PushDataToTagManagerForExternalUrls(
			TagManagerEventKeys.EXTERNAL_URL_BUTTON_CLICK,
			urlKey,
			window.location.href,
			url
		);
	}
}

export function GoToDWDURL(finOrVin: string) {
	const store = getOrCreateStore({});
	const { applicationSettings } = store.getState().configuration;

	if (!isNil(applicationSettings)) {
		const url = applicationSettings[ExternalURLKeys.DYNAMIC_WIRING_URL];
		const encodedFinOrVin = Buffer.from(finOrVin).toString('base64');
		window.open(`${url}?finorvin=${encodedFinOrVin}`);
		PushDataToTagManagerForExternalUrls(
			TagManagerEventKeys.EXTERNAL_URL_BUTTON_CLICK,
			ExternalURLKeys.DYNAMIC_WIRING_URL,
			window.location.href,
			url
		);
	}
}

export const getWheelsURL = () => {
	const store = getOrCreateStore({});
	const { applicationSettings } = store.getState().configuration;

	if (!isNil(applicationSettings)) return applicationSettings[ExternalURLKeys.WEBPARTS_WHEELS_URL];
	return null;
};

export const goToWheelsURL = () => {
	const store = getOrCreateStore({});
	const { applicationSettings } = store.getState().configuration;

	if (!isNil(applicationSettings)) {
		const { organizationId } = store.getState().user;
		const { dealers } = store.getState().organization;
		const config = { APP_ENV: getAppEnvironment() };
		const env = config.APP_ENV;
		const { market, vin } = Router.query;
		const hookUrl = `${(serviceEndpoints as any).config[env].baseUrl}/${market}`;
		const { preferredRetailer } = getRetailersStates(dealers);
		const { deliveryAddresses, id: gssnId } = preferredRetailer ?? {};
		const { customerNumber } = deliveryAddresses?.find(deliveryAddress => deliveryAddress.isPreferred) ?? {};

		const queryParams = {
			appId: 'ispc',
			...(preferredRetailer && { crispId: organizationId, gssnId, customerNumber }),
			...(vin && { fin: vin }),
			context: 'WH',
			inPlace: '10',
			sbPath: `/${market}/basket`,
			hookUrl,
		};

		const queryString = Object.keys(queryParams)
			.map(key => (queryParams[key] ? `${key}=${queryParams[key]}` : ''))
			.join('&');
		const url = `${applicationSettings[ExternalURLKeys.WEBPARTS_WHEELS_URL]}?${queryString}`;

		window.open(url, WindowTargetAttributes.SELF);
		PushDataToTagManagerForExternalUrls(
			TagManagerEventKeys.EXTERNAL_URL_BUTTON_CLICK,
			ExternalURLKeys.WEBPARTS_WHEELS_URL,
			window.location.href,
			url
		);
	}
};

export function GoToUrl(url, target) {
	window.open(url, target);
}

export function OpenMailWindow(toMail, ccMail?, subject?, body?) {
	window.location.href = createEmailLink(toMail, ccMail, subject, body);
}

export const GoToAccessURL = productKey => {
	const urlKey = URLS[productKey];
	GoToExternalURL(urlKey, TRACKABLE_RMI_PRODUCTS.includes(productKey));
};

export const GoToRmiUrl = (baseUrl, productKey) => {
	const urlKey = URLS[productKey];
	const destinationUrl = trackingUrlBuilder(
		baseUrl,
		APPLICATION_UTM_SOURCE_ID,
		UTM_TRAFFIC_TYPE,
		productKey === ProductKeys.WIS
	);
	window.open(destinationUrl, WindowTargetAttributes.BLANK);
	PushDataToTagManagerForExternalUrls(
		TagManagerEventKeys.EXTERNAL_URL_BUTTON_CLICK,
		urlKey,
		window.location.href,
		destinationUrl
	);
};

export function GoToWAOURL(finOrVin: string) {
	const store = getOrCreateStore({});
	const { applicationSettings } = store.getState().configuration;

	if (!isNil(applicationSettings)) {
		const url = applicationSettings[ExternalURLKeys.WHEEL_ALIGNMENT_ONLINE_URL];
		window.open(`${url}&fin=${finOrVin}`);
		PushDataToTagManagerForExternalUrls(
			TagManagerEventKeys.EXTERNAL_URL_BUTTON_CLICK,
			ExternalURLKeys.DYNAMIC_WIRING_URL,
			window.location.href,
			url
		);
	}
}
