import { DealerVisibilities, toDealerVisibilities } from '@Reducers/shop/models';

export interface OrganizationActionsType {
	GET_ORGANIZATION_DEALERS: Function;
	ADD_ORGANIZATION_DEALER: Function;
	HIDE_ORGANIZATION_DEALER_REQUEST: Function;
	GET_ORGANIZATION_DETAILS: Function;
	SET_PREFERRED_DEALER_OR_DELIVERY_ADDRESS: Function;
	UPDATE_PREFERRED_DEALER: Function;
	UPDATE_DELIVERY_ADDRESS: Function;
	SET_DEALER_ACCORDION: Function;
	GET_ORGANIZATION_USERS: Function;
	ASSIGN_ADMIN_RIGHT: Function;
	REVOKE_ADMIN_RIGHT: Function;
	EDIT_ORGANIZATION_MAIL_ADDRESS: Function;
	DELETE_RETAILER: Function;
}

export interface OrganizationInfo {
	gssnId: string;
	organizationId: string;
	taxNo: string;
	type: string;
	mainAddress: object;
	deliveryAddressList: object[];
	emailAddress: string;
	name: string;
}

export interface DeliveryAddress {
	companyId: string;
	customerNumber: string;
	customerName: string;
	description: string;
	zipCode: string;
	city: string;
	country: string;
	district: string;
	region: OrganizationAddressesRegion;
	isPreferred: boolean;
}

export interface OrganizationAddress {
	description: string;
	zipCode: string;
	city: string;
	country: string;
	district: string;
	region: OrganizationAddressesRegion;
}

export interface OrganizationAddressesRegion {
	region: string;
	subRegion: string;
}

export interface Retailer {
	id: string;
	gssnId?: string;
	name: string;
	email: string;
	phoneNumber: string;
	address: OrganizationAddress;
	deliveryAddresses: DeliveryAddress[];
	isPreferred: boolean;
	customerNumberList: string[];
	assignedDivisionList: string[];
	visibilities?: DealerVisibilities;
	isPartsProPlus: boolean;
	status: string;
	hasAvailability: boolean;
	numberOfDecimals: number;
	dataProtectionDeclaration: DataProtectionText;
	oemWarrantyText: string;
	homePageUrl?: string;
}

export interface DataProtectionText {
	text: string;
	documentUrl: string;
	fileName: string;
	downloadToken: string;
}

export interface RetailerOpeningHoursItem {
	productGroup: number;
	brand: number;
	activity: number;
	openDays: RetailerOpenDay[];
}

export interface RetailerOpenDay {
	dayIdentifier: number;
	outletOpeningHoursFrom: string;
	outletOpeningHoursTo: string;
	outletBreakFrom: string;
	outletBreakTo: string;
}

export const toDeliveryAddress = deliveryAddress => ({
	companyId: deliveryAddress?.companyId || '',
	customerNumber: deliveryAddress?.customerNumber || '',
	customerName: deliveryAddress?.customerName || '',
	description: deliveryAddress?.description || '',
	zipCode: deliveryAddress?.zipCode || '',
	city: deliveryAddress?.city || '',
	country: deliveryAddress?.country || '',
	district: deliveryAddress?.district || '',
	region: toOrganizationAddressesRegion(deliveryAddress?.region),
	isPreferred: deliveryAddress?.isPreferred || false,
});

export const toOrganizationAddressesRegion = addressRegion => ({
	region: addressRegion?.region,
	subRegion: addressRegion?.subRegion,
});

export const toOrganizationAddress = address => ({
	description: address?.description || '',
	zipCode: address?.zipCode || '',
	city: address?.city || '',
	country: address?.country || '',
	district: address?.district || '',
	region: toOrganizationAddressesRegion(address?.region),
});

export const toDataProtectionText = data => ({
	text: data?.text || '',
	documentUrl: data?.documentUrl || '',
	fileName: data?.fileName || '',
	downloadToken: data?.downloadToken || '',
});

export const toRetailer = dealer => ({
	id: dealer?.id || '',
	gssnId: dealer?.id || '',
	name: dealer?.name || '',
	email: dealer?.email || '',
	phoneNumber: dealer?.phoneNumber || '',
	address: toOrganizationAddress(dealer?.address),
	deliveryAddresses: dealer?.deliveryAddresses?.map(item => toDeliveryAddress(item)) || [],
	isPreferred: dealer?.isPreferred || false,
	customerNumberList: dealer?.customerNumberList || [],
	assignedDivisionList: dealer?.assignedDivisionList || [],
	visibilities: toDealerVisibilities(dealer?.visibilities),
	status: dealer?.status || '',
	hasAvailability: dealer?.hasAvailability || false,
	numberOfDecimals: dealer?.numberOfDecimals || 0,
	dataProtectionDeclaration:
		dealer?.dataProtectionDeclaration && toDataProtectionText(dealer?.dataProtectionDeclaration),
	oemWarrantyText: dealer?.oemWarrantyText || '',
	homePageUrl: dealer?.homePageUrl || '',
	isPartsProPlus: dealer?.isPartsProPlus || false,
});
